import Views from 'components/views';
import { createBrowserHistory } from 'history';
import { useCurrentUser, useFetchHeaders } from 'hooks';
import { Portal } from 'lib/rmwc';
import { Company as CompanyModel } from 'models/Company';
import { Competency as CompetencyModel } from 'models/Competency';
import {
  Contract as ContractModel,
  ContractTemplate as ContractTemplateModel,
  RejectedContract as RejectedContractModel,
} from 'models/Contract';
import {
  EmployeeAvailability as EmployeeAvailabilityModel,
  Employee as EmployeeModel,
} from 'models/Employee';
import { Industry as IndustryModel } from 'models/Industry';
import { JobNotice as JobNoticeModel } from 'models/JobNotice';
import { Licence as LicenceModel } from 'models/Licence';
import { Profession as ProfessionModel } from 'models/Profession';
import { Profile as ProfileModel } from 'models/Profile';
import { Province as ProvinceModel } from 'models/Province';
import { User as UserModel } from 'models/User';
import { AccountDelete as AccountDeleteModel } from 'models/AccountDelete';
import { useModel, useSession } from 'orm';
import React, { createContext } from 'react';
import { Route, Router } from 'react-router-dom';

export const SessionContext = createContext();

function SessionProvider({ children }) {
  const models = useSession({
    Employee: useModel(EmployeeModel),
    EmployeeAvailability: useModel(EmployeeAvailabilityModel),
    JobNotice: useModel(JobNoticeModel),
    Profession: useModel(ProfessionModel),
    Province: useModel(ProvinceModel),
    Company: useModel(CompanyModel),
    User: useModel(UserModel),
    Profile: useModel(ProfileModel),
    Licence: useModel(LicenceModel),
    Competency: useModel(CompetencyModel),
    Contract: useModel(ContractModel),
    ContractTemplate: useModel(ContractTemplateModel),
    RejectedContract: useModel(RejectedContractModel),
    Industry: useModel(IndustryModel),
    AccountDelete: useModel(AccountDeleteModel),
  });
  const session = {
    ...models,
    CurrentUser: useCurrentUser(models),
    headers: useFetchHeaders(models),
  };
  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
}

export default function App() {
  return (
    <SessionProvider>
      <Router history={createBrowserHistory()}>
        <Route path="/" component={Views} />
      </Router>
      <Portal />
    </SessionProvider>
  );
}

import { SessionContext } from 'App';
import BasicForm from 'components/forms/BasicForm';
import { LeaseContractTemplate } from 'components/snippets/contract/LeaseContractTemplate';
import {
  AcceptContractForm,
  CreateContractForm,
  CreateJobNoticeContractForm,
  RejectContractForm,
} from 'forms/Contract';
import { Dialog } from 'lib/rmwc';
import { Contract } from 'models/Contract';
import { useDeepCompareEffect, useForm } from 'orm';
import React, { useContext, useState } from 'react';
import { Route, useHistory, useLocation, useParams } from 'react-router-dom';

export function CreateContractForEmployeeDialog({ employee }) {
  const history = useHistory();
  const location = useLocation();
  const session = useContext(SessionContext);
  const { ContractTemplate, CurrentUser, headers } = session;
  const contractTemplate = ContractTemplate.first();
  const { currentUser } = CurrentUser;

  useDeepCompareEffect(() => {
    if (!contractTemplate) {
      ContractTemplate.actions.get({ headers });
    }
  }, [contractTemplate]);

  const form = useForm(
    session,
    CreateContractForm,
    {
      defaultValues: {
        employee: employee?.id,
        providing_company: employee?.ref?.company,
        receiving_company: currentUser?.profile?.company?.id,
        created_by: currentUser?.id,
      },
    },
    [
      employee?.id,
      employee?.company?.created_by,
      employee?.ref?.company,
      currentUser?.profile?.company?.id,
      currentUser?.id,
    ],
  );
  const [open, setOpen] = useState(true);

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/contracts';
    },
    cancel: () => {
      form.reset();
      return location?.state?.previous || '/contracts';
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return '/contracts';
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        onClosed={onClose}
        className="mdc-dialog--contract"
      >
        <Dialog.Title>Tee sopimus</Dialog.Title>
        <Dialog.Content>
          {contractTemplate && form.values && (
            <LeaseContractTemplate
              form={form.values}
              contract={{
                ...contractTemplate,
                ...form.values,
                employee: employee,
                providing_company: employee?.company,
                receiving_company: currentUser?.profile?.company,
                created_by: currentUser,
                accepted_by: employee?.company?.created_by,
              }}
              editable={true}
              outlined={true}
              style={{ padding: 0 }}
            />
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Button theme="textPrimaryOnLight" action="cancel">
            Peruuta
          </Dialog.Button>
          <Dialog.Button
            theme="textPrimaryOnLight"
            disabled={form.pristine || form.submitting}
            action="submit"
          >
            Tee sopimus
          </Dialog.Button>
        </Dialog.Actions>
      </Dialog>
      <Route path="/employee/:id/contracts/create/fill">
        <FillContractDialog form={form} />
      </Route>
    </React.Fragment>
  );
}

export function CreateContractForJobNoticeDialog({ jobnotice }) {
  const history = useHistory();
  const location = useLocation();
  const session = useContext(SessionContext);
  const { ContractTemplate, CurrentUser, Employee, headers } = session;
  const contractTemplate = ContractTemplate.first();
  const { currentUser } = CurrentUser;

  useDeepCompareEffect(() => {
    if (!contractTemplate) {
      ContractTemplate.actions.get({ headers });
    }
  }, [contractTemplate]);

  const form = useForm(
    session,
    CreateJobNoticeContractForm,
    {
      defaultValues: {
        jobnotice: jobnotice?.id,
        providing_company: currentUser?.profile?.company?.id,
        receiving_company: jobnotice?.ref?.company,
        created_by: currentUser?.id,
        start_time: jobnotice?.start_time,
        end_time: jobnotice?.end_time,
        location: jobnotice?.location,
      },
    },
    [
      jobnotice?.id,
      jobnotice?.ref?.company,
      jobnotice?.company?.created_by,
      currentUser?.profile?.company?.id,
      currentUser?.id,
    ],
  );

  const [open, setOpen] = useState(true);

  const employee = Employee.get({ id: form.values?.employee });

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/contracts';
    },
    cancel: () => {
      form.reset();
      return location?.state?.previous || '/contracts';
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return '/contracts';
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <React.Fragment>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        onClosed={onClose}
        className="mdc-dialog--contract"
      >
        <Dialog.Title>Tee sopimus</Dialog.Title>
        <Dialog.Content>
          {contractTemplate && form.values && (
            <LeaseContractTemplate
              form={form.values}
              contract={{
                ...contractTemplate,
                ...form.values,
                employee,
                profession: jobnotice?.profession,
                providing_company: currentUser?.profile?.company,
                receiving_company: jobnotice?.company,
                created_by: currentUser,
                accepting_party: Contract.AcceptingParty.RECEIVING_COMPANY,
                accepted_by: jobnotice?.company?.created_by,
              }}
              editable={true}
              outlined={true}
              style={{ padding: 0 }}
            />
          )}
        </Dialog.Content>
        <Dialog.Actions>
          <Dialog.Button theme="textPrimaryOnLight" action="cancel">
            Peruuta
          </Dialog.Button>
          <Dialog.Button
            theme="textPrimaryOnLight"
            disabled={form.pristine || form.submitting}
            action="submit"
          >
            Tee sopimus
          </Dialog.Button>
        </Dialog.Actions>
      </Dialog>
      <Route path="/jobnotice/:id/contracts/create/fill">
        <FillContractDialog form={form} />
      </Route>
    </React.Fragment>
  );
}
export function FillContractDialog({ form }) {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const { id } = useParams();

  const dialogActions = {
    close: () => {
      return location?.state?.previous || `/employee/${id}/contracts/create`;
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClose}>
      <Dialog.Title>Täytä sopimuksen tietoja</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button
          theme="textPrimaryOnLight"
          action="close"
          isDefaultAction
        >
          Sulje
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function AcceptContractDialog({ contract }) {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const { id } = useParams();
  const session = useContext(SessionContext);
  const { CurrentUser } = session;
  const { currentUser } = CurrentUser;

  const form = useForm(
    session,
    AcceptContractForm,
    {
      defaultValues: {
        id: contract?.id,
        accepted_by: currentUser?.id,
        accepted: true,
      },
    },
    [contract?.id, currentUser?.id],
  );

  const dialogActions = {
    close: () => {
      return location?.state?.previous || `/contract/${id}`;
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return `/contract/${id}`;
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onClosed={onClose}
      className="mdc-dialog--contract"
    >
      <Dialog.Title>Hyväksy sopimus</Dialog.Title>
      <Dialog.Content>
        {contract && (
          <LeaseContractTemplate
            form={contract}
            contract={{
              ...contract,
              accepted_by: currentUser?.id,
            }}
            editable={false}
            outlined={true}
            style={{ padding: 0 }}
          />
        )}
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="close">
          Peruuta
        </Dialog.Button>
        <Dialog.Button theme="textPrimaryOnLight" action="submit">
          Hyväksy
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function RejectContractDialog({ contract }) {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const { id } = useParams();
  const session = useContext(SessionContext);

  const form = useForm(
    session,
    RejectContractForm,
    {
      defaultValues: {
        id: contract?.id,
      },
    },
    [contract?.id],
  );

  const dialogActions = {
    close: () => {
      return location?.state?.previous || `/contract/${id}`;
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return `/contracts`;
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      onClosed={onClose}
      className="mdc-dialog--contract"
    >
      <Dialog.Title>Hylkää sopimus</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="close">
          Peruuta
        </Dialog.Button>
        <Dialog.Button theme="textPrimaryOnLight" action="submit">
          Hylkää
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

import { Card, List, Typography } from 'lib/rmwc';
import React from 'react';

import { SimpleTable } from '../SimpleTable';

export function CompanyCard({
  header,
  company,
  person,
  theme = '',
  themeText = '',
  outlined = false,
}) {
  const fullName = [person?.first_name, person?.last_name]
    .filter(Boolean)
    .join(' ');

  const fullAddress = [
    company?.street_address && company?.street_address + ',',
    company?.zip_code,
    company?.city,
  ]
    .filter(Boolean)
    .join(' ');

  return (
    <Card outlined={outlined} theme={theme}>
      <Typography
        use="subtitle1"
        tag="div"
        style={{ padding: '0.5rem 1rem' }}
        theme={themeText}
      >
        {header}
      </Typography>
      <List.Divider />
      <SimpleTable
        theme={theme}
        themeText={themeText}
        items={[
          ['Yrityksen nimi:', company?.name],
          ['Y-tunnus:', company?.business_id],
          ['Henkilön nimi:', fullName],
          ['Sähköposti:', company?.email || person?.email],
          ['Puhelinnumero:', company?.phone || person?.phone],
          ['Yrityksen osoite:', fullAddress],
        ]}
      />
    </Card>
  );
}

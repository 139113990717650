import { SessionContext } from 'App';
import { SearchEmployeesDialog } from 'components/dialogs/SearchEmployee';
import EmployeeList from 'components/snippets/EmployeeList';
import TopAppBar from 'components/snippets/TopAppBar';
import TopAppBarPagination from 'components/snippets/TopAppBarPagination';
import { useDefaultSearchEffect, useLocationSearch } from 'hooks';
import { Button, Grid } from 'lib/rmwc';
import React, { useContext, useState } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

export default function EmployeesView() {
  const location = useLocation();
  const [search, setSearch] = useLocationSearch();
  const [progress, setProgress] = useState(false);

  const { Employee } = useContext(SessionContext);
  const { meta } = Employee;

  useDefaultSearchEffect({
    page: 1,
    page_size: 20,
  });

  const toSearch = {
    pathname: `/employees/search`,
    state: { previous: location },
    search: location.search,
  };

  function onEmployeeListUpdate({ page, pageSize, progress }) {
    setSearch({ ...search, page, page_size: pageSize });
    setProgress(progress);
  }

  return (
    <React.Fragment>
      <Route path="/employees/search" component={SearchEmployeesDialog} />

      <TopAppBar navigationMenu="menu" progress={progress}>
        <TopAppBar.Section>
          <Button
            icon="search"
            label="Tarkenna hakua"
            tag={Link}
            to={toSearch}
          />
        </TopAppBar.Section>
        <TopAppBarPagination {...meta} />
      </TopAppBar>
      <Grid style={{ maxWidth: 1200, paddingTop: 0 }}>
        <Grid.Cell span={12}>
          <EmployeeList
            page={parseInt(search.page)}
            pageSize={parseInt(search.page_size)}
            search={{
              is_same_company: false,
              ...search,
            }}
            usePagination={true}
            title="Hae työntekijöitä"
            subtitle="Tässä listataan lainattavia työntekijöitä. Voit halutessasi tarkentaa hakuehtoja."
            createLabel={false}
            onChange={onEmployeeListUpdate}
            emptyText="Työntekijöitä ei löytynyt näillä hakuehdoilla."
          />
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

import { Model } from 'orm';
import { Industry } from './Industry';

export class Profession extends Model {
  static modelName = 'Profession';

  static fields = {
    id: new this.CharField(),
    name: new this.CharField({ maxLength: 50 }),
    industries: new this.ManyToManyField(Industry),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/professions/:id`,
  };
}

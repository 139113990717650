import { SessionContext } from 'App';
import BasicForm from 'components/forms/BasicForm';
import { CreateCompanyForm, EditCompanyForm } from 'forms/Company';
import { Dialog, TextField, Typography } from 'lib/rmwc';
import { useForm } from 'orm';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function CreateCompanyDialog() {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const form = useForm(session, CreateCompanyForm);
  const [open, setOpen] = useState(true);

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/profile';
    },
    cancel: () => {
      form.reset();
      return location?.state?.previous || '/profile';
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return '/profile';
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClose}>
      <Dialog.Title>Luo yritys</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="cancel">
          Peruuta
        </Dialog.Button>
        <Dialog.Button
          theme="textPrimaryOnLight"
          action="submit"
          isDefaultAction
        >
          Tallenna
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function EditCompanyDialog({ company = {} }) {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const form = useForm(
    session,
    EditCompanyForm,
    {
      defaultValues: company?.ref,
    },
    [company?.ref],
  );
  const [open, setOpen] = useState(true);

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/profile';
    },
    cancel: () => {
      form.reset();
      return location?.state?.previous || '/profile';
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return '/profile';
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClose}>
      <Dialog.Title>Muokkaa yritystä</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="cancel">
          Peruuta
        </Dialog.Button>
        <Dialog.Button
          theme="textPrimaryOnLight"
          action="submit"
          isDefaultAction
        >
          Tallenna
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function DeleteAccountDialog() {
  const history = useHistory();
  const location = useLocation();
  const [open, setOpen] = useState(true);
  const [email, setEmail] = useState('');
  const { User, CurrentUser, headers } = useContext(SessionContext);

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/profile';
    },
    cancel: () => {
      return location?.state?.previous || '/profile';
    },
    submit: async (e) => {
      await User.actions.delete({ headers });
      await User.clear();
      history.go(0);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  const currentEmail = CurrentUser.currentUser?.email;
  const disabled = email.trim() !== currentEmail;

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClose}>
      <Dialog.Title>Poista käyttäjätili</Dialog.Title>
      <Dialog.Content>
        <div>
          Poistamalla käyttäjätilin poistetaan palvelusta tilin alla luodut
          työntekijät ja työtehtäväilmoitukset, eikä tilillä voi enää kirjautua
          sisään. Turvallisuussyistä voimassa olevia palvelusopimuksia tai
          niihin liittyviä tietoja ei poisteta tai peruta.
        </div>
        <div style={{ marginTop: 16 }}>
          <Typography use="subtitle2">Tilin sähköpostitunnus:</Typography>
          <div>{currentEmail}</div>
        </div>
        <div style={{ marginTop: 16 }}>
          Jos haluat varmasti poistaa käyttäjätilisi, syötä sähköpostitunnuksesi
          vahvistaaksesi päätös:
        </div>
        <div style={{ marginTop: 16 }}>
          <TextField
            outlined
            fullwidth
            label="Tilin sähköpostitunnus"
            span={6}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></TextField>
        </div>
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button
          theme="textPrimaryOnLight"
          action="cancel"
          isDefaultAction
        >
          Peruuta
        </Dialog.Button>
        <Dialog.Button danger action="submit" disabled={disabled}>
          Poista käyttäjätili
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

import { SessionContext } from 'App';
import { CreateContractForJobNoticeDialog } from 'components/dialogs/Contract';
import {
  DeleteJobNoticeDialog,
  EditJobNoticeDialog,
} from 'components/dialogs/JobNotice';
import JobNoticeDetailsGrid from 'components/snippets/JobNoticeDetailsGrid';
import TopAppBar from 'components/snippets/TopAppBar';
import { Button } from 'lib/rmwc';
import { useNoUndefinedEffect } from 'orm';
import React, { useContext } from 'react';
import { Link, Route, useLocation, useParams } from 'react-router-dom';

export default function JobNoticeDetail() {
  const location = useLocation();
  const { id } = useParams();

  const { JobNotice, CurrentUser, ContractTemplate, headers } =
    useContext(SessionContext);
  const jobnotice = JobNotice.get({ id: Number(id) });
  const contractTemplate = ContractTemplate.first();

  const belongToCurrentUser =
    jobnotice?.company?.id &&
    jobnotice?.company?.id === CurrentUser.currentUser?.profile?.company?.id;

  useNoUndefinedEffect(() => {
    JobNotice.actions.get({
      id: Number(id),
      headers,
      params: { include: 'jobnotice_details' },
    });
  }, [id]);

  useNoUndefinedEffect(() => {
    if (!contractTemplate) {
      ContractTemplate.actions.get({ headers });
    }
  }, [contractTemplate]);

  const previous = location?.state?.previous || {
    pathname: `/jobnotices`,
  };
  return (
    <React.Fragment>
      <Route path="/jobnotice/:id/edit">
        <EditJobNoticeDialog jobnotice={jobnotice} />
      </Route>
      <Route path="/jobnotice/:id/delete">
        <DeleteJobNoticeDialog jobnotice={jobnotice} />
      </Route>
      <Route path="/jobnotice/:id/contracts/create">
        <CreateContractForJobNoticeDialog jobnotice={jobnotice} />
      </Route>

      <TopAppBar
        navigationMenu="arrow"
        navigationMenuTo={previous}
        progress={!jobnotice}
      >
        <TopAppBar.Section>
          {belongToCurrentUser ? (
            <>
              <Button
                icon="delete"
                label="Poista"
                tag={Link}
                to={{
                  pathname: `/jobnotice/${id}/delete`,
                }}
              />
              <Button
                icon="add"
                label="Muokkaa"
                tag={Link}
                to={{
                  pathname: `/jobnotice/${id}/edit`,
                  state: { previous: location },
                }}
              />
            </>
          ) : (
            <>
              <Button
                icon="add"
                label="Tee sopimus"
                tag={Link}
                to={{
                  pathname: `/jobnotice/${id}/contracts/create`,
                  state: {
                    previous: location,
                  },
                }}
              />
            </>
          )}
        </TopAppBar.Section>
      </TopAppBar>
      <JobNoticeDetailsGrid
        jobnotice={jobnotice}
        contractTemplate={contractTemplate}
      />
    </React.Fragment>
  );
}

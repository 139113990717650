import { YearsToRanges } from 'converters';
import { Card, Grid, Typography } from 'lib/rmwc';
import React from 'react';
import { Link } from 'react-router-dom';
import { renderLineBreaks } from 'renderUtils';

export default function EmployeeDetailsGrid({
  employee,
  contracts = [],
  contractTemplate = null,
}) {
  const includesCommission = !!contractTemplate?.brokerage_commision;
  const costIncludingCommission =
    employee?.getCostIncludingCommission?.(contractTemplate) || 0;

  const cards = [
    [
      includesCommission
        ? 'Tuntiveloitushinta, sisältäen välityspalkkion (alv. 0%)'
        : 'Tuntiveloitushinta (alv. 0%)',
      costIncludingCommission
        ? `${costIncludingCommission.toFixed(2)} €/h`
        : '-',
    ],
    [
      'Työkokemus',
      employee?.experience_years
        ? YearsToRanges(employee.experience_years)
        : '-',
    ],
    [
      'Työtehtävät ja vastuualueet',
      <ul style={{ margin: 0, paddingInlineStart: '1rem' }}>
        {(employee?.competencies || []).map(({ id, name }) => (
          <li key={id}>{name}</li>
        ))}
      </ul>,
    ],
    [
      'Kortit',
      <ul style={{ margin: 0, paddingInlineStart: '1rem' }}>
        {(employee?.licences || []).map(({ id, name }) => (
          <li key={id}>{name}</li>
        ))}
      </ul>,
    ],
    ['Työskentelykunta', employee?.province?.name || ''],
    [
      'Käytettävissä',
      <div>
        {employee?.is_available === false && (
          <Typography
            use="body1"
            tag="div"
            theme="error"
            style={{ padding: '0 0 0.5rem' }}
          >
            Työnhaku ei aktiivisena
          </Typography>
        )}
        {employee?.available_from} — {employee?.available_to}
      </div>,
    ],
  ];

  return (
    <Grid style={{ maxWidth: 1200 }}>
      <Grid.Cell span={4}>
        <Card theme="secondaryBg" style={{ marginBottom: '1rem' }}>
          {employee?.picture && (
            <Card.Media
              sixteenByNine
              style={{
                backgroundImage: `url(${employee?.picture})`,
              }}
            />
          )}
          <div style={{ padding: '0 1rem' }}>
            <Typography
              use="headline6"
              tag="h2"
              theme="textSecondaryOnBackground"
            >
              {employee?.name}
            </Typography>
            <Typography
              use="subtitle2"
              tag="h3"
              theme="textSecondaryOnBackground"
              style={{ marginTop: '-1rem' }}
            >
              {employee?.profession?.name}
            </Typography>
            <Typography use="body1" tag="div" theme="textSecondaryOnBackground">
              {renderLineBreaks(employee?.description ?? '')}
            </Typography>
          </div>
        </Card>
        {contracts.length && (
          <Card theme="secondaryBg">
            <div style={{ padding: '0.5rem 1rem' }}>
              <Typography
                use="subtitle1"
                tag="div"
                theme="textSecondaryOnBackground"
              >
                Hyväksytyt sopimukset
              </Typography>
              {contracts.map((contract) => (
                <div
                  key={contract.id}
                  style={{
                    padding: '0.25rem 1rem',
                  }}
                >
                  <Typography
                    tag={Link}
                    to={`/contract/${contract.id}`}
                    theme="primary"
                    style={{ textDecoration: 'none' }}
                  >
                    {contract.start_time} — {contract.end_time}
                  </Typography>
                </div>
              ))}
            </div>
          </Card>
        )}
      </Grid.Cell>
      <Grid.Cell span={8}>
        <Grid.Row>
          {cards.map(([subtitle, body], i) => (
            <Grid.Cell span={12} key={i}>
              <Card theme="secondaryBg">
                <Typography
                  use="subtitle1"
                  tag="div"
                  theme="textSecondaryOnBackground"
                  style={{
                    padding: '0.5rem 1rem 0.25rem',
                  }}
                >
                  {subtitle}
                </Typography>
                <Typography
                  use="body1"
                  tag="span"
                  theme="textSecondaryOnBackground"
                  style={{
                    padding: '0 2rem 0.5rem',
                  }}
                >
                  {body}
                </Typography>
              </Card>
            </Grid.Cell>
          ))}
        </Grid.Row>
      </Grid.Cell>
    </Grid>
  );
}

import { SessionContext } from 'App';
import { Select } from 'lib/rmwc';
import { useDeepCompareEffect, useNoUndefinedEffect } from 'orm';
import React, { useContext, useState } from 'react';

import { Autocomplete } from './Autocomplete';

const toOptions = ({ id, name }) => ({ value: id, label: name });
const filterByIndustry =
  (industry) =>
  ({ ref }) =>
    ref.industries.includes(industry);
const sortByName = (a, b) =>
  a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;

export function ProfessionAutocompleteSelect({ form, value, ...rest }) {
  const { Profession } = useContext(SessionContext);
  const [options, setOptions] = useState([]);

  const industry =
    Number(form.values.industry || form.values.company__industry) || 0;

  useDeepCompareEffect(() => {
    const professions = industry
      ? Profession.all().filter(filterByIndustry(industry))
      : Profession.all();
    setOptions(professions.sort(sortByName).map(toOptions));
  }, [industry, Profession.all().length]);

  if (!industry) {
    rest.label = 'Valitse ensin toimiala';
  }

  return (
    <Autocomplete
      options={options}
      value={value}
      disabled={!industry}
      {...rest}
    />
  );
}

export function ProvinceAutocompleteSelect(props) {
  const { Province } = useContext(SessionContext);

  const options = Province.all().map(toOptions);

  return <Autocomplete {...props} options={options} />;
}

export function YearsOfExperienceSelect({ value, fullwidth, ...props }) {
  const yearOfExperienceMap = [
    { value: 0, label: '--' },
    { value: 1, label: '1-2 vuotta' },
    { value: 3, label: '3-5 vuotta' },
    { value: 6, label: '6-10 vuotta' },
    { value: 11, label: 'yli 10 vuotta' },
  ];
  return (
    <Select
      {...props}
      style={{ width: '100%' }}
      value={value.toString()}
      options={yearOfExperienceMap}
    />
  );
}

export function LicencesAutocompleteMultiSelect(props) {
  const { Licence, headers } = useContext(SessionContext);

  useDeepCompareEffect(() => {
    Licence.actions.get({ headers });
  }, []);

  const options = Licence.all().map(toOptions);

  return <Autocomplete multiple options={options} {...props} />;
}

export function CompetenciesAutocompleteMultiSelect({ form, ...props }) {
  const { Competency, headers } = useContext(SessionContext);
  const industry = form.values.industry || form.values.company__industry;

  useNoUndefinedEffect(() => {
    Competency.actions.get({
      headers,
      params: { page: 1, page_size: 500, industry },
    });
  }, [industry]);

  const competencies = industry
    ? Competency.all().filter(filterByIndustry(industry))
    : Competency.all();
  const options = competencies.sort(sortByName).map(toOptions);

  if (!industry) {
    props.label = 'Valitse ensin toimiala';
  }

  return (
    <Autocomplete multiple options={options} {...props} disabled={!industry} />
  );
}

export function CompanyAutocompleteSelect({ form, value, ...props }) {
  const { Company, headers } = useContext(SessionContext);

  useDeepCompareEffect(() => {
    Company.actions.get({ headers });
  }, []);

  const options = Company.all().map(toOptions);

  return <Autocomplete options={options} value={value} {...props} />;
}

export function IndustryAutocompleteSelect({ form, value, ...props }) {
  const { Industry } = useContext(SessionContext);

  const options = Industry.all().map(toOptions);

  return <Autocomplete options={options} value={value} {...props} />;
}

export function EmployeeAutocompleteSelect({ form, value, ...props }) {
  const { Employee, headers } = useContext(SessionContext);
  const { providing_company } = form.values;

  useNoUndefinedEffect(() => {
    Employee.actions.get({
      headers,
      params: {
        is_same_company: false,
        company: providing_company,
        include: 'employee_details',
      },
    });
  }, [providing_company]);

  useDeepCompareEffect(() => {
    Employee.delete();
  }, []);

  const options = Employee.all().map(toOptions);

  return <Autocomplete options={options} value={value} {...props} />;
}

export function MyEmployeeAutocompleteSelect({ form, value, ...props }) {
  const { Employee, headers } = useContext(SessionContext);
  const { providing_company } = form.values;

  useDeepCompareEffect(() => {
    Employee.delete();
  }, []);

  useNoUndefinedEffect(() => {
    Employee.actions.get({
      headers,
      params: {
        is_same_company: true,
        company: providing_company,
        include: 'employee_details',
      },
    });
  }, [providing_company]);

  const options = Employee.all()
    .filter((e) => e.company.id === providing_company)

    .map(toOptions);

  const noneAvailable = !options || options.length === 0;

  return (
    <Autocomplete
      options={options}
      {...props}
      value={value}
      label={noneAvailable ? 'Ei vapaita työntekijöitä' : props.label}
      disabled={noneAvailable}
    />
  );
}

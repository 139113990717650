import React from 'react';
import { TextField } from 'lib/rmwc';

export function ImageField({ form, value, onChange, ...props }) {
  function _onChange(e) {
    return onChange({ target: { value: e.target.files[0] } });
  }
  return (
    <TextField
      className={'mdc-text-field--file ' + props.className}
      onChange={_onChange}
      {...props}
    >
      {props.children}
      <label htmlFor={props.id}>{value?.name || 'Choose file...'}</label>
    </TextField>
  );
}

import { Model } from 'orm';

import { Company } from './Company';
import { Employee } from './Employee';
import { Profession } from './Profession';
import { User } from './User';

export class Contract extends Model {
  static modelName = 'Contract';

  static fields = {
    id: new this.CharField(),
    created_at: new this.CharField(),
    start_time: new this.CharField(),
    end_time: new this.CharField(),
    cost_per_predefined_time: new this.CharField(),
    brokerage_commision: new this.CharField(),
    updated_at: new this.CharField(),
    providing_company: new this.OneToOneField(Company, {
      relatedName: 'contract_providing_set',
    }),
    receiving_company: new this.OneToOneField(Company, {
      relatedName: 'contract_receiving_set',
    }),
    employee: new this.OneToOneField(Employee, { relatedName: 'contracts' }),
    created_by: new this.OneToOneField(User, { relatedName: 'contracts' }),
    accepted_by: new this.OneToOneField(User, { relatedName: 'contracts' }),
    profession: new this.ForeignKey(Profession, { relatedName: 'profession' }),
    accepted: new this.CharField(),
    location: new this.CharField(),
    details: new this.CharField(),
    accepting_party: new this.CharField(),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/contracts/:id`,
  };

  static AcceptingParty = {
    PROVIDING_COMPANY: 'PC',
    RECEIVING_COMPANY: 'RC',
  };

  reduce(payload) {
    if (payload.results) {
      const { results = null, ...meta } = payload;
      super.meta = meta;
      super.reduce(results);
    } else {
      super.reduce(payload);
    }
  }

  parse(payload) {
    const { Company, Employee, User } = this.session;
    return super.parse({
      ...payload,
      created_by: User.parse(payload?.created_by),
      accepted_by: User.parse(payload?.accepted_by),
      providing_company: Company.parse(payload?.providing_company),
      receiving_company: Company.parse(payload?.receiving_company),
      employee: Employee.parse(payload?.employee),
    });
  }

  static methods = {
    isCompanyAcceptingParty(company) {
      if (!company?.id) {
        return false;
      }
      const isProvider = this.providing_company?.id === company.id;
      const providerAccepts =
        this.accepting_party === Contract.AcceptingParty.PROVIDING_COMPANY;
      return isProvider === providerAccepts;
    },

    getReceivingPerson() {
      return this.isCompanyAcceptingParty(this.receiving_company)
        ? this.accepted_by
        : this.created_by;
    },

    getProvidingPerson() {
      return this.isCompanyAcceptingParty(this.providing_company)
        ? this.accepted_by
        : this.created_by;
    },

    getCommissionInDecimal() {
      return Number(this.brokerage_commision || 0) / 100;
    },

    getCostIncludingCommission() {
      return (
        Number(this.cost_per_predefined_time || 0) *
        (1 + this.getCommissionInDecimal())
      );
    },
  };
}

export class ContractTemplate extends Contract {
  static modelName = 'ContractTemplate';

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/contracts/template/`,
  };

  reduce(payload) {
    payload.id = 'template';
    super.reduce(payload);
  }
}

export class RejectedContract extends Model {
  static modelName = 'RejectedContract';

  static fields = {
    id: new this.CharField(),
    reason: new this.CharField(),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/contracts/:id/reject/`,
  };
}

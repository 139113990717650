import { SessionContext } from 'App';
import ContractDetailView from 'components/views/ContractDetail';
import ContractListView from 'components/views/ContractList';
import EmployeeDetailView from 'components/views/EmployeeDetail';
import EmployeesView from 'components/views/Employees';
import HomeView from 'components/views/Home';
import JobNoticeDetailView from 'components/views/JobNoticeDetail';
import JobNoticesView from 'components/views/JobNotices';
import LandingView from 'components/views/LandingView';
import ProfileView from 'components/views/Profile';
import { Snackbar } from 'lib/rmwc';
import { useDeepCompareEffect } from 'orm';
import React, { useContext } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import Manual from './Manual';
import PrivacyPolicy from './PrivacyPolicy';
import TermsOfService from './TermsOfService';
import AccountDeleteRequest from './AccountDeleteRequest';

export default function Index() {
  const location = useLocation();
  const {
    User,
    CurrentUser,
    Contract,
    Province,
    Industry,
    Profession,
    headers,
  } = useContext(SessionContext);

  const { isLoggedIn, isUserLoaded, hasCreatedCompany } = CurrentUser;

  useDeepCompareEffect(() => {
    User.actions.get({ headers, params: { include: 'company_details' } });
    if (isLoggedIn) {
      // Fetch globally useful model data eagerly at the start
      Province.actions.get({ headers });
      Industry.actions.get({ headers });
      Profession.actions.get({ headers });
    }
  }, [isLoggedIn]);

  const userError = User?.meta?.errors?.non_field_errors?.[0];
  const contractError = Contract?.meta?.errors?.__all__?.[0];
  const isPasswordResetSent =
    User?.meta?.detail === 'Password reset e-mail has been sent.';

  return (
    <React.Fragment>
      {isLoggedIn &&
        isUserLoaded &&
        !hasCreatedCompany &&
        !location.pathname.startsWith('/profile') && (
          <Redirect to="/profile/company/create" />
        )}

      {isLoggedIn && location.pathname.startsWith('/delete-account') && (
        <Redirect to="/profile/delete" />
      )}

      {isLoggedIn && (
        <Switch>
          <Route path="/home" component={HomeView} />
          <Route path="/employee/:id" component={EmployeeDetailView} />
          <Route path="/employees" component={EmployeesView} />
          <Route path="/jobnotice/:id" component={JobNoticeDetailView} />
          <Route path="/jobnotices" component={JobNoticesView} />
          <Route path="/contracts" component={ContractListView} />
          <Route path="/contract/:id" component={ContractDetailView} />

          <Route path="/" exact component={LandingView} />
          <Route path="/login" component={LandingView} />
          <Route path="/register" component={LandingView} />
          <Route path="/password/reset" component={LandingView} />
          <Route path="/profile" component={ProfileView} />

          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/manual" component={Manual} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
        </Switch>
      )}

      {!isLoggedIn && (
        <Switch>
          <Route path="/" exact component={LandingView} />
          <Route path="/login" component={LandingView} />
          <Route path="/register" component={LandingView} />
          <Route path="/password/reset" component={LandingView} />
          <Route path="/profile" component={ProfileView} />

          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/manual" component={Manual} />
          <Route path="/privacy-policy" component={PrivacyPolicy} />
          <Route path="/delete-account" component={AccountDeleteRequest} />
          <Redirect to="/" />
        </Switch>
      )}

      {!!userError && (
        <Snackbar
          open
          onClose={() => {
            User.meta = { ...User.meta, errors: undefined };
            User._rerender();
          }}
          message={userError}
          dismissesOnAction
          action={<Snackbar.Action label="Selvä" />}
        />
      )}

      {!!contractError && (
        <Snackbar
          open
          onClose={() => {
            Contract.meta = { ...Contract.meta, errors: undefined };
            Contract._rerender();
          }}
          message={contractError}
          dismissesOnAction
          action={<Snackbar.Action label="Selvä" />}
        />
      )}

      {isPasswordResetSent && (
        <Snackbar
          open
          onClose={() => {
            User.meta = { ...User.meta, detail: undefined };
            User._rerender();
          }}
          message="Ohjeet salasanan palauttamiseksi on lähetetty sähköpostiisi."
          dismissesOnAction
          action={<Snackbar.Action label="Selvä" />}
        />
      )}
    </React.Fragment>
  );
}

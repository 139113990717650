import axios from 'axios'
import { useState } from 'react'

import { useInstance } from '../hooks'

class Related {
  constructor(relatedModel, object) {
    Object.defineProperty(this, 'ref', {
      get: function () {
        return object
      }
    })
    for (let key in {...relatedModel.constructor.fields, ...relatedModel.constructor.methods}) {
      Object.defineProperty(this, key, {
        get: function () {
          return object?.[key]
        }
      })
    }
  }
}

export class Model {
  static modelName = 'Model'
  static options = {
    idAttribute: 'id',
    persist: false,
  }

  constructor(rerender) {
    this._rerender = rerender
    this._objects = []
    this._meta = {}
    this._relatedFields = []
    this._actions = null
    for (let key in (this.constructor.fields ?? {})) {
      const field = this.constructor.fields[key].constructor
      field.setModel(this)
      if (this.constructor.fields[key] instanceof RelatedField) {
        this._relatedFields.push(key)
      }
    }
  }

  get objects() {
    if (this.constructor.options.persist) {
      const persisted = window.localStorage.getItem(`${this.constructor.modelName}.objects`)
      if (persisted) this._objects = JSON.parse(persisted)
    }

    return this._objects
  }

  set objects(objects) {
    this._objects = objects
    if (this.constructor.options.persist) {
      const stringified = JSON.stringify(this._objects)
      window.localStorage.setItem(`${this.constructor.modelName}.objects`, stringified)
    }
  }

  get meta() {
    if (this.constructor.options.persistMeta) {
      const persisted = window.localStorage.getItem(`${this.constructor.modelName}.meta`)
      if (persisted) this._meta = JSON.parse(persisted)
    }
    return this._meta
  }

  set meta(meta) {
    this._meta = meta
    if (this.constructor.options.persistMeta) {
      const stringified = JSON.stringify(this._meta)
      window.localStorage.setItem(`${this.constructor.modelName}.meta`, stringified)
    }
  }

  get actions() {
    if (!this._actions) {
      this._actions = {
        ...this._generateActions(),
        ...this.constructor.actions
      }
    }
    return this._actions
  }

  _request = (config) => {
    const { idAttribute, apiEndpoint } = this.constructor.options
    const idRegex = new RegExp(`:${idAttribute}/?`)
    const url = apiEndpoint.replace(idRegex, config?.[idAttribute] ? `${config?.[idAttribute]}/` : '')
    const { headers = {}, method = 'get', ...configRest } = config
    return axios({
      url,
      method,
      headers: {
        'Content-Type': 'application/json',
        ...headers,
      },
      ...configRest,
    })
      .then((response) => {
        return this.handleResponse({ response })
      })
      .catch((error) => {
        return this.handleResponse({ response: error.response, error })
      })
  }

  _generateActions = () => {
    const modelName = this.constructor.modelName
    const modelNamePlural = this.constructor.modelNamePlural || modelName + 's'
    const methods = ['get', 'post', 'put', 'delete']
    return methods.reduce((acc, method) => {
      const call = async (config) => {
        const response = await this._request({
          method,
          ...config
        })
        this._rerender()
        return response
      }
      return {
        ...acc,
        [method]: call,
        [method + modelName]: call,
        [method + modelNamePlural]: call,
      }
    }, {})
  }

  setSession = (session) => {
    this.session = session
  }

  _generateRelated = (field, lookupValue) => {
    if (!field.relational) return lookupValue
    const relatedModel = this.session[field.relation.modelName]
    if (!relatedModel) return lookupValue

    const { idAttribute } = relatedModel.constructor.options
    if (field instanceof OneToOneField) {
      const object = relatedModel.get({ [idAttribute]: lookupValue })
      return new Related(relatedModel, object)
    } else if (field instanceof ForeignKey) {
      const object = relatedModel.get({ [idAttribute]: lookupValue })
      return new Related(relatedModel, object)
    } else if (field instanceof ManyToManyField) {
      return relatedModel
        .filter({ [idAttribute]: lookupValue })
        .map((object) => new Related(relatedModel, object))
    }
  }

  all = () => {
    return this.objects.map((object) => {
      let props = {}

      Object.defineProperty(props, 'ref', {
        get: function () {
          return object
        }
      })

      for (let key in this.constructor.fields) {
        const field = this.constructor.fields[key]
        const lookupValue = object[key]
        props[key] = this._generateRelated(field, lookupValue)
      }
      for (let key in this.constructor.methods) {
        props[key] = this.constructor.methods[key]
      }
      return props
    })
  }

  filter = (args) => {
    const { idAttribute } = this.constructor.options
    const ids = Array.isArray(args?.[idAttribute])
      ? args?.[idAttribute]
      : Array(args?.[idAttribute])
    return this.all().filter((object) => {
      if (ids.includes(object[idAttribute])) {
        return true
      }
      for (let key in args) {
        if (args[key] === object[key]) {
          return true
        }
      }
      return false
    })
  }

  exclude = (args) => {
    const { idAttribute } = this.constructor.options
    const ids = Array.isArray(args[idAttribute])
      ? args[idAttribute]
      : Array(args[idAttribute])
    return this.all().filter((object) => {
      if (ids.includes(object[idAttribute])) return false
      for (let key in args) {
        if (args[key] === object[key]) return false
      }
      return true
    })
  }

  get = (args) => {
    const objects = this.filter(args)
    if (objects.length > 1) throw Error('Multiple objects returned.')
    return objects.length ? objects[0] : null
  }

  first = () => {
    return this.all()[0] ?? null
  }

  create = (obj, rerender = this._rerender) => {
    const { idAttribute } = this.constructor.options
    if (!obj?.[idAttribute]) return false
    this.objects = [...this.objects, obj]
    rerender()
    return obj
  }

  update = (newObject, rerender = this._rerender) => {
    const idAttr = this.constructor.options.idAttribute
    const existing = this.get({ [idAttr]: newObject[idAttr] })
    if (existing) {
      this.objects = this.objects.map((object) => {
        return object[idAttr] === newObject[idAttr] ? newObject : object
      })
      rerender()
      return newObject
    }
    return false
  }

  upsert = (obj, rerender) => {
    const updated = this.update(obj, rerender)
    if (updated) return updated
    return this.create(obj, rerender)
  }

  delete = (args, rerender = this._rerender) => {
    if (!args) {
      this.objects = []
      rerender()
      return this.all()
    }
    const objects = this.filter(args)
    if (objects.length > 1) throw Error('Multiple objects returned.')
    this.objects = this.exclude(args)
    rerender()
    return objects
  }

  clear = (rerender = this._rerender) => {
    this.objects = []
    this.meta = {}
    rerender()
  }

  handleResponse({ response, error }) {
    if (response && error) {
      this.meta = { ...this.meta, errors: response.data }
      return this.meta
    } else if (!response && error) {
      throw Error(error)
    }
    return this.reduce(response.data)
  }

  reduce(payload) {
    const isArray = Array.isArray(payload)
    if (isArray) this.parseArray(payload)
    else this.parse(payload)
  }

  parse(payload) {
    const { idAttribute } = this.constructor.options
    if (payload) {
      for (const key of this._relatedFields) {
        const data = payload?.[key]
        if (data) {
          const relatedModel = this.session[this.constructor.fields[key].relation.modelName]
          const parsed = Array.isArray(data) ? relatedModel.parseArray(data) : relatedModel.parse(data)
          payload[key] = parsed
        }
      }
    }
    if (payload?.[idAttribute]) {
      return this.upsert(payload, () => { })[idAttribute]
    }
    return payload || null
  }

  parseArray(payloadArr) {
    return (payloadArr || []).map(obj => this.parse(obj))
  }
}

export const useModel = (ModelClass) => {
  const [, rerender] = useState({ rerender: null })

  const model = useInstance(
    () => new ModelClass(() => rerender({ rerender: null })),
    [ModelClass, rerender]
  )

  return model
}

export class Field {
  constructor(args, supportedArgs) {
    for (let key in args) {
      if (supportedArgs && !supportedArgs.includes(key)) {
        throw new Error('Passed in argument is not supported.')
      }
    }
  }
  static setModel(model) {
    this.model = model
    if (this.relation) {
      this.relation.fields[this.relatedName] = new this(this.model)
    }
  }
}
Model.Field = Field

export class BooleanField extends Field { }
Model.BooleanField = BooleanField

export class IntegerField extends Field { }
Model.IntegerField = IntegerField

export class ArrayField extends Field { }
Model.ArrayField = ArrayField

export class CharField extends Field {
  constructor(args) {
    const supportedArgs = ['maxLength', 'minLength', 'strip', 'emptyValue']
    super(args, supportedArgs)
  }
}
Model.CharField = CharField

export class RelatedField extends Field {
  relational = true
  constructor(relation, args, _supportedArgs = []) {
    const supportedArgs = [..._supportedArgs, 'relatedName']
    super(args, supportedArgs)
    if (!relation instanceof Model)
      throw Error('First parameter should be Model instance.')
    this.relation = relation
    this.relatedName = args?.relatedName
  }
}
Model.RelatedField = RelatedField

export class ForeignKey extends RelatedField { }
Model.ForeignKey = ForeignKey

export class OneToOneField extends RelatedField { }
Model.OneToOneField = OneToOneField

export class ManyToManyField extends RelatedField { }
Model.ManyToManyField = ManyToManyField

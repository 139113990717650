import { Model } from 'orm';

export class Company extends Model {
  static modelName = 'Company';
  static modelNamePlural = 'Companies';

  static fields = {
    id: new this.CharField(),
    name: new this.CharField({ maxLength: 50 }),
    billing_information: new this.CharField(),
    business_id: new this.CharField(),
    city: new this.CharField(),
    created_by: new this.CharField(),
    description: new this.CharField(),
    email: new this.CharField(),
    industry: new this.CharField(),
    phone: new this.CharField(),
    street_address: new this.CharField(),
    zip_code: new this.CharField(),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/companies/:id`,
  };
}

import { DataTable } from 'lib/rmwc';
import React from 'react';

export function SimpleTable({ theme = '', themeText = '', items = [] }) {
  return (
    <DataTable.Simple
      theme={theme}
      style={{ border: 0 }}
      data={items.map(([key, value]) => [key, value || '-'])}
      getRowProps={(row) => ({
        style: { border: 0 },
      })}
      getCellProps={(cell, index, isHead) => ({
        alignEnd: index > 0,
        theme: themeText,
      })}
    />
  );
}

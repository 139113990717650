import { CreateEmployeeDialog } from 'components/dialogs/Employee';
import { CreateJobNoticeDialog } from 'components/dialogs/JobNotice';
import EmployeeList from 'components/snippets/EmployeeList';
import JobNoticeList from 'components/snippets/JobNoticeList';
import TopAppBar from 'components/snippets/TopAppBar';
import { useDefaultSearchEffect, useLocationSearch } from 'hooks';
import { Button, Grid } from 'lib/rmwc';
import React, { useState } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';

export default function Home() {
  const location = useLocation();
  const [employeeProgress, setEmployeeProgress] = useState(false);
  const [jobNoticeProgress, setJobNoticeProgress] = useState(false);
  const [search, setSearch] = useLocationSearch();

  useDefaultSearchEffect({
    pe: 1,
    se: 10,
    pj: 1,
    sj: 10,
  });

  function onEmployeeListUpdate({ page, pageSize, progress }) {
    setSearch({ ...search, pe: page, se: pageSize });
    setEmployeeProgress(progress);
  }
  function onJobNoticeListUpdate({ page, pageSize, progress }) {
    setSearch({ ...search, pj: page, sj: pageSize });
    setJobNoticeProgress(progress);
  }

  return (
    <React.Fragment>
      <Route path="/home/create-employee" component={CreateEmployeeDialog} />
      <Route path="/home/create-jobnotice" component={CreateJobNoticeDialog} />

      <TopAppBar
        navigationMenu="menu"
        progress={employeeProgress || jobNoticeProgress}
      >
        <TopAppBar.Section>
          <Button
            icon="add"
            label="Lisää työtehtävä"
            tag={Link}
            to={{
              ...location,
              pathname: `/home/create-jobnotice`,
              state: { previous: location },
            }}
          />
        </TopAppBar.Section>
        <TopAppBar.Section>
          <Button
            icon="add"
            label="Lisää työntekijä"
            tag={Link}
            to={{
              ...location,
              pathname: `/home/create-employee`,
              state: { previous: location },
            }}
          />
        </TopAppBar.Section>
      </TopAppBar>

      <Grid style={{ maxWidth: 1200, paddingTop: 0 }}>
        <Grid.Cell span={12}>
          <EmployeeList
            page={search.pe}
            pageSize={search.se}
            search={{
              is_same_company: true,
            }}
            onChange={onEmployeeListUpdate}
            emptyText="Jos olet tarjoamassa työvoimaa määräaikaisesti lainaksi muille yrityksille, lisää tässä vapaat työntekijäsi, jotta olette näkyvillä työntekijöiden hakulistauksessa. Yksityisyrittäjät lisäävät itsensä."
            style={{ paddingBottom: 0 }}
          />
          <JobNoticeList
            page={search.pj}
            pageSize={search.sj}
            search={{
              is_same_company: true,
            }}
            onChange={onJobNoticeListUpdate}
            emptyText="Jos olet etsimässä itsellesi lainattavaa työntekijää, lisää tässä ilmoitukset vapaista työtehtävistäsi, jotta työvoiman tarjoajat voivat löytää sen työtehtävien hakulistauksesta."
            style={{ paddingTop: 1 }}
          />
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

import React from 'react';
import { Avatar } from 'rmwc';

export function EmployeeAvatar({ employee }) {
  const hasPicture = !!employee?.picture;
  return (
    <Avatar
      src={employee?.picture}
      name={employee?.name || ''}
      size={hasPicture ? 'large' : 'medium'}
      theme={hasPicture ? 'secondaryBg' : 'primaryBg'}
    />
  );
}

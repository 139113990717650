import { SessionContext } from 'App';
import BasicForm from 'components/forms/BasicForm';
import { Dialog } from 'lib/rmwc';
import { useForm } from 'orm';
import query from 'query-string';
import React, { useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function SearchDialog({ formClass, pathname, title }) {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();

  const search = location.search;
  const form = useForm(
    session,
    formClass,
    {
      defaultValues: { ...query.parse(search) },
    },
    [search],
  );

  const dialogActions = {
    submit: () => {
      return {
        pathname,
        search: query.stringify(form.values),
      };
    },
  };

  function onClose(e) {
    history.push(dialogActions[e.detail.action]?.() ?? { pathname, search });
  }

  return (
    <Dialog open onClosed={onClose}>
      <Dialog.Title>{title}</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="cancel">
          Peruuta
        </Dialog.Button>
        <Dialog.Button
          theme="textPrimaryOnLight"
          action="submit"
          isDefaultAction
        >
          Hae
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

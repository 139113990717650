import { SessionContext } from 'App';
import BasicForm from 'components/forms/BasicForm';
import LoginForm from 'forms/Login';
import PasswordResetForm from 'forms/PasswordReset';
import RegisterForm from 'forms/Register';
import { Dialog } from 'lib/rmwc';
import { useForm } from 'orm';
import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

export function LoginDialog() {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const form = useForm(session, LoginForm);
  const [open, setOpen] = useState(true);

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/';
    },
    cancel: () => {
      form.reset();
      return location?.state?.previous || '/';
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return '/home';
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) history.push(next);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClose}>
      <Dialog.Title>Kirjaudu sisään</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button
          className="justify-start"
          tag={Link}
          to="/password/reset"
        >
          Unohdin salasanani
        </Dialog.Button>
        <Dialog.Button action="cancel">Peruuta</Dialog.Button>
        <Dialog.Button
          disabled={form.pristine || form.submitting}
          action="submit"
          isDefaultAction
        >
          Kirjaudu
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function RegisterDialog() {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const form = useForm(session, RegisterForm);
  const [open, setOpen] = useState(true);

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/';
    },
    cancel: () => {
      form.reset();
      return location?.state?.previous || '/';
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return '/profile/company/create';
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) history.push(next);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClose}>
      <Dialog.Title>Rekisteröidy</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button action="cancel">Peruuta</Dialog.Button>
        <Dialog.Button
          disabled={form.pristine || form.submitting}
          action="submit"
          isDefaultAction
        >
          Rekisteröidy
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function PasswordResetDialog() {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const form = useForm(session, PasswordResetForm);
  const [open, setOpen] = useState(true);

  const dialogActions = {
    close: () => {
      return location?.state?.previous || '/';
    },
    cancel: () => {
      form.reset();
      return location?.state?.previous || '/';
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return '/login';
      }
      setOpen(true);
    },
  };

  async function onClose(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) history.push(next);
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClose}>
      <Dialog.Title>Palauta salasana</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button action="cancel">Peruuta</Dialog.Button>
        <Dialog.Button
          disabled={form.pristine || form.submitting}
          action="submit"
          isDefaultAction
        >
          Palauta
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

import { Model } from 'orm';

import { Company } from './Company';

export class Profile extends Model {
  static modelName = 'Profile';

  static fields = {
    id: new this.CharField(),
    company: new this.ForeignKey(Company, { relatedName: 'profile' }),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_AUTH_URL}/user/`,
    persistMeta: true,
  };
}

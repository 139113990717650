import { Button, CircularProgress, Icon, List, Typography } from 'lib/rmwc';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function ResourceList({
  render,
  onPageChange,
  meta = null,
  progress = false,
  resources = [],
  title = '',
  subtitle = '',
  createLabel = '',
  createPathname = '',
  style = {},
  emptyText = '',
}) {
  const location = useLocation();

  const totalPages =
    meta?.page &&
    meta?.pageSize &&
    meta?.count &&
    Math.ceil(meta.count / meta.pageSize);

  const showPagination = meta && totalPages && totalPages > 1;

  return (
    <React.Fragment>
      <List theme="primaryBg" twoLine avatarList style={style}>
        {title !== false && (
          <List.Group theme={['secondaryBg', 'textPrimaryOnBackground']}>
            <List.Item disabled={true}>
              <div>
                <Typography use="headline6" tag="div">
                  {title}
                </Typography>
                <Typography
                  use="subtitle1"
                  tag="div"
                  theme="textSecondaryOnBackground"
                >
                  {subtitle}
                </Typography>
              </div>
            </List.Item>
          </List.Group>
        )}
        {progress ? (
          <List.Group theme="secondaryBg">
            <List.Item>
              <CircularProgress size="xlarge" />
            </List.Item>
          </List.Group>
        ) : (
          <>
            <List.Group theme={['secondaryBg', 'textPrimaryOnBackground']}>
              {!resources.length && emptyText && (
                <List.Item disabled={true}>
                  <Icon
                    icon={{ icon: 'error_outline', size: 'large' }}
                    style={{ paddingRight: '1rem' }}
                  />
                  <span>{emptyText}</span>
                </List.Item>
              )}
              {render(resources)}
              {createLabel !== false && (
                <List.Item disabled={true}>
                  <Button
                    icon="add"
                    label={createLabel}
                    tag={Link}
                    to={{
                      ...location,
                      pathname: createPathname,
                      state: { previous: location },
                    }}
                  />
                </List.Item>
              )}
            </List.Group>

            {showPagination && (
              <List.Group theme={['secondaryBg', 'textPrimaryOnBackground']}>
                <List.Item disabled={true} style={{ justifyContent: 'center' }}>
                  <Button
                    disabled={!meta.previous}
                    theme={
                      !meta.previous
                        ? 'textDisabledOnBackground'
                        : 'textSecondaryOnBackground'
                    }
                    icon="keyboard_arrow_left"
                    onClick={() => onPageChange(-1)}
                  />
                  {totalPages && (
                    <Typography
                      use="subtitle1"
                      tag="span"
                      theme="textSecondaryOnBackground"
                      style={{ margin: '0 1rem' }}
                    >
                      {meta.page} / {totalPages}
                    </Typography>
                  )}
                  <Button
                    disabled={!meta.next}
                    theme={
                      !meta.next
                        ? 'textDisabledOnBackground'
                        : 'textSecondaryOnBackground'
                    }
                    icon="keyboard_arrow_right"
                    onClick={() => onPageChange(1)}
                  />
                </List.Item>
              </List.Group>
            )}
          </>
        )}
      </List>
    </React.Fragment>
  );
}

import { Checkbox } from 'lib/rmwc';
import React from 'react';

export function IsAvailableCheckBox({
  form,
  value,
  onChange,
  invalid,
  ...props
}) {
  function _onChange(e) {
    return onChange({ target: { value: !value } });
  }
  return <Checkbox onChange={_onChange} {...props} checked={!!value} />;
}

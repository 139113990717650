import { Card, Grid, Icon, Typography } from 'lib/rmwc';
import React from 'react';
import { renderLineBreaks } from 'renderUtils';

export default function JobNoticeDetailsGrid({ jobnotice }) {
  const isLate = Date.parse(jobnotice?.start_time) < Date.now();

  const cardSubtitleStyle = {
    padding: '0.5rem 1rem 0.25rem',
  };
  const cardBodyStyle = {
    padding: '0 2rem 0.5rem',
  };

  const cardColumns = [
    [
      ['Kuvaus', renderLineBreaks(jobnotice?.details || '')],
      [
        'Toimipiste tai työkohteen osoite',
        renderLineBreaks(jobnotice?.location || ''),
      ],
    ],
    [
      ['Yritys', jobnotice?.company?.name || ''],
      ['Ammatti', jobnotice?.profession?.name || ''],
      ['Työskentelykunta', jobnotice?.province?.name || ''],
      [
        'Ajankohta',
        <>
          <div>
            {jobnotice?.start_time} — {jobnotice?.end_time}
          </div>
          {isLate && (
            <Typography tag="div" use="subtitle2" theme="error">
              <Icon
                icon={{ icon: 'schedule', size: 'small' }}
                style={{ marginRight: '0.2rem', verticalAlign: 'middle' }}
              />
              <span>
                Tämä työilmoitus on vanhentunut eikä sen vuoksi näy
                hakutuloksissa. Muokkaa ajankohtaa tai poista ilmoitus.
              </span>
            </Typography>
          )}
        </>,
      ],
    ],
  ];

  return (
    <Grid style={{ maxWidth: 1200 }}>
      {cardColumns.map((cards, i) => (
        <Grid.Cell span={12 / cardColumns.length} key={i}>
          <Grid.Row>
            {cards.map(([subtitle, body], i) => (
              <Grid.Cell span={12} key={i}>
                <Card className="mdc-theme--secondary-bg">
                  <Typography
                    use="subtitle1"
                    tag="div"
                    style={cardSubtitleStyle}
                    theme="textSecondaryOnBackground"
                  >
                    {subtitle}
                  </Typography>
                  <Typography
                    use="body1"
                    tag="span"
                    theme="textSecondaryOnBackground"
                    style={cardBodyStyle}
                  >
                    {body}
                  </Typography>
                </Card>
              </Grid.Cell>
            ))}
          </Grid.Row>
        </Grid.Cell>
      ))}
    </Grid>
  );
}

import { Model } from 'orm';

export class Licence extends Model {
  static modelName = 'Licence';

  static fields = {
    id: new this.CharField(),
    name: new this.CharField({ maxLength: 50 }),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/licences/`,
  };
}

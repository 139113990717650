import { SessionContext } from 'App';
import { EmployeeAvatar } from 'components/snippets/EmployeeAvatar';
import TopAppBar from 'components/snippets/TopAppBar';
import TopAppBarPagination from 'components/snippets/TopAppBarPagination';
import { useDefaultSearchEffect, useLocationSearch } from 'hooks';
import { Card, CircularProgress, Grid, List, TabBar } from 'lib/rmwc';
import { useNoUndefinedEffect } from 'orm';
import React, { useContext, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

export default function ContractList() {
  const [progress, setProgress] = useState(false);
  const location = useLocation();
  const [search, setSearch] = useLocationSearch();

  const { Contract, headers } = useContext(SessionContext);
  const { meta } = Contract;

  useDefaultSearchEffect({
    page: 1,
    page_size: 20,
    accepted: true,
    expired: false,
  });

  useNoUndefinedEffect(() => {
    (async () => {
      setProgress(true);
      Contract.delete();
      await Contract.actions.get({
        headers,
        params: {
          ...search,
          include: 'contract_details',
        },
      });
      setProgress(false);
    })();
  }, [search, search.page, search.page_size]);

  const contracts = Contract.all();

  function activeTabIndex() {
    if (search.expired === 'false' && search.accepted === 'true') {
      return 0;
    } else if (search.expired === 'false' && search.accepted === 'false') {
      return 1;
    } else if (search.expired === 'true' && search.accepted === 'true') {
      return 2;
    }
  }

  function onActivate(e) {
    if (e.detail.index === 0) {
      setSearch({ accepted: true, expired: false });
    } else if (e.detail.index === 1) {
      setSearch({ accepted: false, expired: false });
    } else if (e.detail.index === 2) {
      setSearch({ accepted: true, expired: true });
    }
  }

  return (
    <React.Fragment>
      <TopAppBar navigationMenu="menu" progress={progress}>
        <TopAppBarPagination {...meta} />
      </TopAppBar>

      <Grid style={{ maxWidth: 1200, paddingTop: 0, gridRowGap: 0 }}>
        <Grid.Cell span={12}>
          <TabBar activeTabIndex={activeTabIndex} onActivate={onActivate}>
            <TabBar.Tab>Voimassa</TabBar.Tab>
            <TabBar.Tab>Odottaa hyväksyntää</TabBar.Tab>
            <TabBar.Tab>Päättyneet</TabBar.Tab>
          </TabBar>
          <Card>
            <List
              className="mdc-list--custom"
              theme="primaryBg"
              twoLine
              avatarList
            >
              <List.Group>
                {progress && (
                  <List.Item theme="secondaryBg">
                    <CircularProgress size="xlarge" />
                  </List.Item>
                )}
                {!progress && !contracts.length && (
                  <List.Item theme="secondaryBg">
                    Ei sopimuksia tällä välilehdellä.
                  </List.Item>
                )}
                {contracts.map(({ id, providing_company, employee }) => (
                  <List.Item
                    key={id}
                    theme="secondaryBg"
                    tag={Link}
                    to={{
                      pathname: `/contract/${id}`,
                      state: { previous: location },
                    }}
                  >
                    <List.Item.Graphic
                      icon={<EmployeeAvatar employee={employee} />}
                    />
                    <List.Item.Text>
                      <List.Item.PrimaryText>
                        {employee.name}
                      </List.Item.PrimaryText>
                      <List.Item.SecondaryText>
                        {providing_company.name}
                      </List.Item.SecondaryText>
                    </List.Item.Text>
                  </List.Item>
                ))}
              </List.Group>
            </List>
          </Card>
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

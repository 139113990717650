import { ProvinceAutocompleteSelect } from 'components/forms/fields/Select';
import { ProfessionAutocompleteSelect } from 'components/forms/fields/Select';
import { IndustryAutocompleteSelect } from 'components/forms/fields/Select';
import { TextField } from 'lib/rmwc';
import { JobNotice } from 'models/JobNotice';
import { Form } from 'orm';

export class CreateJobNoticeForm extends Form {
  static model = JobNotice;

  static onSubmit = this.postSubmit;

  static fields = {
    id: new this.ID(),
    industry: new this.CharField(IndustryAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Toimiala',
        outlined: true,
        fullwidth: true,
      },
    }),
    profession: new this.CharField(ProfessionAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Ammatti *',
        outlined: true,
        fullwidth: true,
      },
    }),
    details: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Työtehtävän kuvaus *',
        type: 'text',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
    province: new this.CharField(ProvinceAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Työskentelykunta *',
        textarea: false,
        outlined: true,
        fullwidth: true,
      },
    }),
    location: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Toimipiste tai työkohteen osoite *',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
    start_time: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Työajankohta alkaa *',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
    end_time: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Työajankohta päättyy *',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
  };
}

export class EditJobNoticeForm extends CreateJobNoticeForm {
  static onSubmit = this.putSubmit;
}

export class SearchJobNoticesForm extends Form {
  static model = JobNotice;

  static fields = {
    profession__industries: new this.CharField(IndustryAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Toimiala',
        outlined: true,
        fullwidth: true,
        clearable: true,
      },
    }),
    province: new this.CharField(ProvinceAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Työskentelykunta',
        outlined: true,
        fullwidth: true,
        clearable: true,
      },
    }),
  };
}

import { SessionContext } from 'App';
import { Icon, List } from 'lib/rmwc';
import { useDeepCompareEffect } from 'orm';
import React, { useContext, useState } from 'react';

function convertDate(date) {
  const [year, month, day] = date.split('-').map((number) => parseInt(number));
  return `${day}.${month}.${year}`;
}

export function DateRangeList(form, value, ...props) {
  const [currentStartTime, setCurrentStartTime] = useState(null);
  const [currentEndTime, setCurrentEndTime] = useState(null);
  const [contractDateList, setContractDateList] = useState([]);
  const { EmployeeAvailability, headers } = useContext(SessionContext);

  const id = Number(form.form.values?.employee);
  const start_time = form.form.values?.start_time;
  const end_time = form.form.values?.end_time;

  useDeepCompareEffect(() => {
    (async () => {
      if (currentStartTime !== start_time || currentEndTime !== end_time) {
        setCurrentStartTime(start_time);
        setCurrentEndTime(end_time);
        if (!id || !(start_time && end_time)) {
          return setContractDateList([]);
        } else {
          const params = { start_time, end_time };
          await EmployeeAvailability.actions.get({ headers, id, params });
          const availability = EmployeeAvailability.get({ id });
          setContractDateList(availability?.overlapping_contracts ?? []);
        }
      }
    })();
  });

  if (contractDateList?.length > 0 ?? 0)
    return (
      <List nonInteractive theme="onSurface">
        <List.Group>
          <List.Item disabled={true} key="header" theme="error">
            <Icon
              icon={{ icon: 'error_outline', size: 'medium' }}
              style={{ paddingRight: '0.5rem' }}
            />
            Työntekijällä on jo tälle ajalle sopimuksia:
          </List.Item>
          {contractDateList.map(({ start_time, end_time }, i) => (
            <List.Item disabled={true} key={i}>
              {convertDate(start_time)} — {convertDate(end_time)}
            </List.Item>
          ))}
        </List.Group>
      </List>
    );
  else return <></>;
}

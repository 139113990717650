import { List, Typography } from 'lib/rmwc';
import { Icon } from 'lib/rmwc';
import React from 'react';
import { Link } from 'react-router-dom';

export function JobNoticeListItem({ jobnotice, to, location }) {
  const isLate = Date.parse(jobnotice.start_time) < Date.now();
  return (
    <List.Item
      theme="secondaryBg"
      tag={Link}
      to={{
        pathname: to,
        state: { previous: location },
      }}
    >
      <List.Item.Text>
        <List.Item.PrimaryText>
          {jobnotice.company?.name}{' '}
          {jobnotice.province?.name && `(${jobnotice.province?.name})`}
        </List.Item.PrimaryText>
        <List.Item.SecondaryText>
          {isLate && (
            <Typography
              use="subtitle2"
              theme="error"
              style={{
                marginRight: '0.25rem',
                verticalAlign: 'middle',
              }}
            >
              <Icon
                icon={{ icon: 'schedule', size: 'small' }}
                style={{ marginRight: '0.2rem', verticalAlign: 'middle' }}
              />
              <span>Vanhentunut</span>
            </Typography>
          )}
          {jobnotice.details}
        </List.Item.SecondaryText>
      </List.Item.Text>
    </List.Item>
  );
}

import { TextField } from 'lib/rmwc';
import { User } from 'models/User';
import { Form } from 'orm';

export default class LoginForm extends Form {
  static model = User;

  static onSubmit = ({ data, actions }) => {
    return actions.post({
      url: `${process.env.REACT_APP_AUTH_URL}/login/`,
      data: {
        username: data.username,
        email: data.username,
        password: data.password,
      },
    });
  };

  static fields = {
    username: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Sähköpostiosoite',
        outlined: true,
        fullwidth: true,
      },
    }),
    password: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Salasana',
        type: 'password',
        outlined: true,
        fullwidth: true,
      },
    }),
  };
}

import { SessionContext } from 'App';
import {
  AcceptContractDialog,
  RejectContractDialog,
} from 'components/dialogs/Contract';
import TopAppBar from 'components/snippets/TopAppBar';
import { LeaseContractTemplate } from 'components/snippets/contract/LeaseContractTemplate';
import { Button } from 'lib/rmwc';
import { useDeepCompareEffect } from 'orm';
import query from 'query-string';
import React, { useContext } from 'react';
import { Link, Route, useLocation, useParams } from 'react-router-dom';

export default function ContractDetail() {
  const location = useLocation();
  const { id } = useParams();

  const { Contract, CurrentUser, headers } = useContext(SessionContext);

  const { currentUser } = CurrentUser;
  const contract = Contract.get({ id: Number(id) });

  useDeepCompareEffect(() => {
    Contract.actions.get({
      id: Number(id),
      headers,
      params: {
        include: ['contract_details', 'employee_details'],
      },
      paramsSerializer: query.stringify,
    });
  }, []);

  const previous = location?.state?.previous || { pathname: '/contracts' };

  const userAccepts =
    contract?.isCompanyAcceptingParty(currentUser?.profile?.company) ?? false;

  const userShouldAccept = userAccepts && !contract?.accepted;

  if (userShouldAccept) {
    contract.accepted_by = currentUser;
  }

  return (
    <React.Fragment>
      <Route path="/contract/:id/edit">
        <AcceptContractDialog contract={contract} />
      </Route>
      <Route path="/contract/:id/reject">
        <RejectContractDialog contract={contract} />
      </Route>

      <TopAppBar
        navigationMenu="arrow"
        navigationMenuTo={previous}
        progress={!contract}
      >
        {userShouldAccept && (
          <TopAppBar.Section>
            <Button
              icon="add"
              label="Hyväksy sopimus"
              tag={Link}
              to={{
                pathname: `/contract/${id}/edit`,
                state: { previous: location },
              }}
            />
            <Button
              icon="block"
              label="Hylkää sopimus"
              tag={Link}
              to={{
                pathname: `/contract/${id}/reject`,
                state: { previous: location },
              }}
            />
          </TopAppBar.Section>
        )}
      </TopAppBar>
      <LeaseContractTemplate
        contract={contract}
        editable={false}
        outlined={false}
        theme="secondaryBg"
        themeText="textSecondaryOnBackground"
        themeButton="textPrimaryOnDark"
        style={{ maxWidth: 1200 }}
      />
    </React.Fragment>
  );
}

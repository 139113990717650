import { useEffect, useRef } from 'react'
import isEqual from 'lodash.isequal'

function deepCompareEquals(a, b) {
  return isEqual(a, b)
}

export function useDeepCompareMemoize(value) {
  const ref = useRef()
  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }
  return ref.current
}

export function useDeepCompareEffect(callback, dependencies) {
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export function useNoUndefinedEffect(callback, dependencies) {
  if (dependencies.includes(undefined)) {
    callback = () => {}
  }
  useEffect(callback, useDeepCompareMemoize(dependencies))
}

export function useInstance(callback, dependencies) {
  const undefinedDependeciesIncluded = dependencies.includes(undefined)
  if (undefinedDependeciesIncluded) {
    callback = () => {}
  }
  const ref = useRef()
  if (!ref.current) {
    ref.current = callback()
  }
  return ref.current
}

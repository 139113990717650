import React from 'react';
import { Grid, Typography } from 'lib/rmwc';
import TopAppBar from 'components/snippets/TopAppBar';

export default function Manual() {
  return (
    <React.Fragment>
      <TopAppBar
        navigationMenu="arrow"
        navigationMenuUrl="/"
        progress={false}
      ></TopAppBar>

      <Grid style={{ maxWidth: 1200 }}>
        <Grid.Cell span={12} style={{ justifySelf: 'center' }}>
          <Typography className="mdc-theme--on-primary">
            <h1>Vaihtopenkki-palvelun yleiset ohjeet</h1>

            <h2>Yritystietojen kirjaus:</h2>
            <p>
              Rekisteröitymisesi lisäksi sinun on kirjattava tarvittavat
              yritystiedot, voidaksesi tilata tai tarjota työntekijöitä
              palvelussa. Sinun tulee syöttää järjestelmään yrityksesi{' '}
              <b>
                nimi, y-tunnus, käyntiosoite, toimiala, puhelinnumero,
                sähköpostiosoite ja laskutustiedot/-osoite.
              </b>
            </p>
            <p>
              Näiden tietojen pohjalta järjestelmä luo sinulle aina
              automaattisesti sopimuspohjat, olit sitten tarjoamassa tai
              tilaamassa työntekijää palvelussa. Tämä helpottaa arkeasi sillä
              sinun ei tarvitse myöhemmin nähdä vaivaa tietojen kirjaamiseen
              aina uudelleen.
            </p>

            <h2>Työntekijän tietojen kirjaaminen:</h2>
            <p>
              Kun kirjaat lainattavaksi tarjoamasi työntekijän tietoja
              järjestelmään, sinun tulee täyttää ainakin seuraavat osiot;{' '}
              <b>
                nimi, ammattinimike, kokemusvuodet, toimialue, hinta sekä
                ajanjakso
              </b>{' '}
              jolloin työntekijäsi on käytettävissä.
            </p>
            <p>
              Kun haluat parantaa työntekijäsi mahdollisuuksia tulla lainatuksi,
              sinun kannattaa myös täyttää työntekijän ominaisuuksia tarkentavat
              osiot. Näitä ovat{' '}
              <b>
                kuva, työkortit, ammattiosaaminen, työkalut ja ajoneuvot sekä
                vapaa luonnehdinta tekstikenttä.
              </b>{' '}
              Kuvan voit valita joko käyttämäsi laitteen muistista tai napata
              suoraan puhelimellasi. Osa tarkentavien osien tietoruuduista ovat
              liukuvalikoita, joten sellaisissa voit klikkailla kaikki
              työntekijääsi kuvaavat ominaisuudet helposti samalla kertaa ja
              painaa tallenna nappia.
            </p>
            <p>
              <i>
                (Ethän syötä järjestelmään työntekijäsi nimen lisäksi mitään
                hänen henkilökohtaisia tietoja, kuten esimerkiksi;
                henkilötunnus, osoite, puhelinnumero tai poliittinen
                suuntautuminen tms. Nämä ovat tietosuojan piiriin kuuluvia
                asioita, emmekä vastaa niistä koska järjestelmä ei vaadi
                toimiakseen sellaisia.)
              </i>
            </p>
            <p>
              <b>Hinnan syöttäminen.</b> Työntekijäsi hinta muodostuu siten,
              että lasket työntekijän palkan sekä sivukulut yhteen ja saat
              summaksi hintatiedon. Jokaisella toimialalla on omat
              työehtosopimuksensa ja yrityksillä velvoitteensa sivukulujen
              osalta, sinun tulee siis laskea hinta yrityksellesi aiheutuvien
              todellisten kulujen mukaan.
            </p>
            <p>
              Esimerkiksi rakentamisen alalla yleisesti käytetty sivukulujen
              kerroin on noin 1,7 joten hinta muodostuu seuraavasti: työntekijän
              palkka 15,00€/h x sivukulut 1,7 = 25,5€/h <b>(alv.0%)</b>
            </p>
            <p>
              <i>
                (Vapaan hinnoittelun tarkoituksena ei ole mahdollistaa suuren
                tuloksen teko työntekijöidesi lainasopimuksilla, vaan tarjota
                apua yrityksellesi jotta et joutuisi lomauttamaan henkilöstöäsi
                tai ettei henkilöstösi irtisanoudu hetkellisesti huonontuneen
                suhdanteen aikana.)
              </i>
            </p>

            <h2>Työntekijöiden hakutoiminto:</h2>
            <p>
              Kun etsit järjestelmästä sopivaa työntekijää yrityksesi
              tarpeisiin, sinun tulee syöttää tiedot mitä{' '}
              <b>ammattinimikettä</b> etsit, miltä
              <b>toimialueelta</b> ja mihin <b>ajankohtaan</b>. Voit myös
              tarkentaa hakuasi täydentämällä hakukenttiä työntekijän
              erityisestä
              <b>
                ammattiosaamisesta, työkorteista ja ammattiluvista sekä
                työkokemuksesta.
              </b>
            </p>
            <p>
              <i>
                (Huomioithan että järjestelmässä ilmoitetut hinnat
                työntekijöille ovat arvonlisäverottomia, eikä tämä sisällä
                Palvelun välityspalkkiota. Välityspalkkio määräytyy
                tilaussopimuksen ajanjakson mukaan ja on aina edullisempi, mitä
                pidempi tilausaika on kyseessä.)
              </i>
            </p>
            <p>
              Kun löydät järjestelmästä yrityksesi tarpeisiin sopivalta
              vaikuttavan tekijän, voit painaa <b>”Tee sopimus”</b>-painiketta
              ja järjestelmä tekee sinulle automaattisesti sopimuksen, joka{' '}
              <u>odottaa molempien osapuolten hyväksyntää.</u>
            </p>
            <p>
              Mikäli et löydä järjestelmästä sopivaa työntekijää antamillasi
              hakuehdoilla, voit tallentaa hakuehdoistasi oman <b>hakuvahdin</b>{' '}
              ja saat puhelimeesi myöhemmin ilmoituksen, kun järjestelmässä on
              hakuehtojasi vastaava työntekijä tarjolla.{' '}
              <u>Tämä toiminto toimii ainoastaan mobiiliapplikaatiossa.</u>
            </p>

            <h2>Sopimuksen teko ja laskutus:</h2>
            <p>
              Kun olet löytänyt järjestelmästä sopivalta vaikuttavan työntekijän
              ja painanut tämän kohdalla ”Tee sopimus”-painiketta, järjestelmä
              luo automaattisesti sekä sinun että työntekijän tarjoavan
              yrityksen tiedoilla sopimuspohjan.{' '}
              <u>
                Sopimus odottaa molempien osapuolten hyväksyntää, ennen kuin se
                tallentuu vahvistettuna tehtyihin sopimuksiin.
              </u>{' '}
              Järjestelmässä on omissa asetuksissasi erilliset kansiot
              <b>
                vahvistusta odottaville sopimuksille, vahvistetuille voimassa
                oleville sopimuksille ja päättyneille vanhoille sopimuksille.
              </b>{' '}
              Voit siis aina tarkistaa ajantasaisen sopimustilanteesi tai
              tekemiesi sopimusten sisällön ilman suurta vaivannäköä.
            </p>
            <p>
              <u>
                Sopimuksen vahvistusvaiheessa sinulla on velvollisuus tarkistaa
                osaltasi, että sisältö on kirjattuna oikeilla tiedoilla ja että
                erityisesti hinta, tilauksen ajanjakso sekä työkohteen osoite
                ovat varmasti oikein.
              </u>{' '}
              Näiden perusteella vahvistuu myös laskutusoikeus osapuolten
              välillä, joten olethan tarkkana. Välityspalkkion osuus kirjautuu
              sopimukseen automaattisesti sopimusajanjakson pituuden perusteella
              ja <u>näkyy prosentteina sopimuksen hintaosiossa.</u>
            </p>
            <p>
              <b>
                <u>
                  Tehty sopimus vahvistuu sitovaksi, kun molemmat osapuolet ovat
                  kuitanneet sen hyväksytyksi.
                </u>
              </b>
            </p>
            <p>
              Molemmilla osapuolilla on velvollisuus ilmoittaa palvelun
              tarjoajalle, mikäli huomaa sopimuksessa virheitä tai jos sopimus
              on kirjattuna väärin perustein.
            </p>

            <p>
              Toivottavasti tarjoamme sinulle hyvän käyttökokemuksen.
              Asiakaspalvelumme vastaa kysymyksiisi, jos jokin jää vielä
              ohjeiden jälkeen askarruttamaan. Olethan rohkeasti yhteydessä.
            </p>
          </Typography>
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

import { useState } from 'react'

import { useInstance } from '../hooks'

export class Form {
  submitting = false
  pristine = false
  errors = {}

  constructor(session, rerender, props) {
    this.defaultValues = props?.defaultValues || null
    this.session = session
    this._rerender = rerender
    this.model = session[this.constructor.model.modelName]
    for (let [key, field] of Object.entries(this.constructor.fields)) {
      field.setValue(this.defaultValues?.[key] ?? "")
      field.name = key
      field.form = this
    }
  }

  get values() {
    return Object.fromEntries(
      Object.entries(this.constructor.fields)
      .map(([key, field]) => {
        const value = field.value ?? this.defaultValues?.[key]
        return value !== "" ? [key, value] : undefined
      })
      .filter(Boolean)
    )
  }

  get fields() {
    return Object.values(this.constructor.fields)
      .filter(field => !(field instanceof ID))
      .map(field => ({
        ...field.props,
        name: field.name,
        value: field.value,
        Field: field.component,
        onChange: field.onChange,
      }))
  }

  reset = () => {
    this.pristine = true
    this.submitting = false
    this.errors = {}
    for (let key in this.constructor.fields) {
      this.constructor.fields[key].setValue(this.defaultValues?.[key] ?? "")
    }
    this._rerender()
  }

  onSubmit = async () => {
    const _onSubmit = this.constructor.onSubmit
    const response = await _onSubmit({
      model: this.model,
      actions: this.model.actions,
      data: this.values,
      session: this.session,
      headers: this.session.headers,
    })
    if (response?.errors) this.errors = response.errors
    this._rerender()
    return response
  }

  static postSubmit = ({ data, actions, headers }) => {
    return actions.post({ headers, data });
  };

  static putSubmit = ({ data, actions, headers }) => {
    return actions.put({ headers, id: data.id, data });
  };
}

export const useForm = (session, FormClass, props, dependencies = []) => {
  const [, rerender] = useState({ rerender: null })

  const form = useInstance(
    () => new FormClass(session, () => rerender({ rerender: null }), props),
    [FormClass, rerender, ...dependencies]
  )

  return form || {}
}

export class Field {
  value = null
  static defaultValue = null
  static onChangeCallback = 'onChange'

  constructor(component, args, props) {
    this.setValue(this.constructor.defaultValue)
    this.component = component
    this.args = args
    this.props = props

    if (args.onChangeCallback) {
      this.constructor.onChangeCallback = args.onChangeCallback
    }
  }

  onChange = (e) => {
    this.setValue(e?.target?.value)
    this.form.pristine = false
    this.form._rerender()
  }

  setValue(v) {
    this.value = v
  }
}

export class CharField extends Field {
  static defaultValue = ''
  constructor(component, args) {
    super(component, args, args.extra)
  }
}

export class NumberField extends CharField {
  setValue(v) {
    if (v === "" || v === undefined) {
      this.value = v
    } else {
      this.value = Number(v)
    }
  }
}

export class ArrayField extends Field {
  static defaultValue = []
  constructor(component, args) {
    super(component, args, args.extra)
  }

  setValue(v) {
    if (!v) {
      this.value = []
    } else if (!Array.isArray(v)) {
      this.value = [v]
    } else {
      this.value = v
    }
  }
}

export class NumberArrayField extends Field {
  static defaultValue = []
  constructor(component, args) {
    super(component, args, args.extra)
  }

  setValue(v) {
    if (v === '' || v === undefined) {
      this.value = []
    } else if (!Array.isArray(v)) {
      this.value = [Number(v)]
    } else {
      this.value = v.map(Number)
    }
  }
}

export class BooleanField extends CharField {
  // setValue(v) {
  //   this.value = Boolean(v)
  // }
}

export class ID {
  setValue() {}
}

Form.Field = Field
Form.CharField = CharField
Form.NumberField = NumberField
Form.ArrayField = ArrayField
Form.NumberArrayField = NumberArrayField
Form.BooleanField = BooleanField
Form.ID = ID

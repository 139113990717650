import { SessionContext } from 'App';
import { EmployeeListItem } from 'components/snippets/EmployeeListItem';
import { useDeepCompareEffect, useNoUndefinedEffect } from 'orm';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ResourceList from './ResourceList';

export default function EmployeeList({
  page,
  pageSize,
  onChange,
  usePagination = true,
  search = {},
  style = {},
  emptyText = '',
  title = 'Työntekijät',
  subtitle = '',
  createLabel = 'Lisää työntekijä',
}) {
  const [progress, setProgress] = useState(false);
  const location = useLocation();

  const { Employee, headers } = useContext(SessionContext);

  useDeepCompareEffect(() => {
    Employee.delete();
  }, []);

  useNoUndefinedEffect(() => {
    (async () => {
      setProgress(true);
      Employee.delete();
      await Employee.actions.get({
        headers,
        params: {
          ...search,
          include: 'employee_details',
          page: page,
          page_size: pageSize,
        },
      });
      setProgress(false);
    })();
  }, [page, pageSize, search]);

  useNoUndefinedEffect(() => {
    onChange({ ...search, page, pageSize, progress });
  }, [progress]);

  const employees = Employee.all();

  function onPageChange(direction) {
    onChange({ ...search, page: Number(page) + direction, pageSize, progress });
  }

  return (
    <React.Fragment>
      <ResourceList
        page={page}
        onPageChange={onPageChange}
        meta={usePagination && { ...Employee.meta, page, pageSize }}
        progress={progress}
        title={title}
        subtitle={subtitle}
        createLabel={createLabel}
        createPathname="/home/create-employee"
        style={style}
        emptyText={emptyText}
        resources={employees}
        render={(resources) =>
          resources.map((employee) => (
            <EmployeeListItem
              key={employee.id}
              employee={employee}
              to={`/employee/${employee.id}`}
              location={location}
            />
          ))
        }
      />
    </React.Fragment>
  );
}

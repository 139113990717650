import { useDeepCompareEffect } from 'orm';
import query from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

export function useLocationSearch() {
  const history = useHistory();
  const location = useLocation();
  const _search = query.parse(location.search);

  function setSearch(params, replace = false) {
    const search = query.stringify({ ..._search, ...params });
    if (replace) {
      history.replace({ ...location, search });
    } else {
      history.push({ ...location, search });
    }
  }

  return [_search, setSearch];
}

export function useDefaultSearchEffect(defaultSearch) {
  const [search, setSearch] = useLocationSearch();
  return useDeepCompareEffect(() => {
    if (Object.keys(defaultSearch).some((k) => !(k in search))) {
      setSearch({ ...defaultSearch, ...search }, true);
    }
  }, [search]);
}

export function useFetchHeaders({ User }) {
  return {
    Authorization: `Token ${User.meta.key}`,
  };
}

export function useCurrentUser({ User }) {
  const currentUser = User.first();
  const isLoggedIn = !!User.meta.key;
  const isUserLoaded = !!currentUser;
  const hasCreatedCompany = !!currentUser?.profile?.company?.id;
  return {
    currentUser,
    isLoggedIn,
    isUserLoaded,
    hasCreatedCompany,
  };
}

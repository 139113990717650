import { SessionContext } from 'App';
import {
  CreateCompanyDialog,
  DeleteAccountDialog,
  EditCompanyDialog,
} from 'components/dialogs/Company';
import { SimpleTable } from 'components/snippets/SimpleTable';
import TopAppBar from 'components/snippets/TopAppBar';
import { Button, Card, Grid, Typography } from 'lib/rmwc';
import { useDeepCompareEffect } from 'orm';
import React, { useContext } from 'react';
import { Link, Route, useLocation } from 'react-router-dom';
import { renderLineBreaks } from 'renderUtils';

export default function Profile() {
  const location = useLocation();
  const { User, Industry, headers, CurrentUser } = useContext(SessionContext);
  const { currentUser, hasCreatedCompany } = CurrentUser;
  const industry = Industry.get({
    id: currentUser?.profile?.company?.industry,
  });

  useDeepCompareEffect(() => {
    User.actions.get({ headers, params: { include: 'company_details' } });
  }, []);

  return (
    <React.Fragment>
      <Route path="/profile/delete" component={DeleteAccountDialog} />
      <Route path="/profile/company/create" component={CreateCompanyDialog} />
      <Route path="/profile/company/edit">
        <EditCompanyDialog company={currentUser?.profile?.company} />
      </Route>

      <TopAppBar navigationMenu="menu" progress={!currentUser}></TopAppBar>
      <Grid style={{ maxWidth: 1200 }}>
        <Grid.Cell span={4}>
          <Card className="mdc-theme--secondary-bg">
            {currentUser?.picture && (
              <Card.Media
                sixteenByNine
                style={{
                  backgroundImage: `url(${currentUser?.picture})`,
                }}
              />
            )}
            <div style={{ padding: '0 1rem 1rem 1rem' }}>
              <Typography
                use="headline6"
                tag="h2"
                theme="textSecondaryOnBackground"
              >
                {currentUser?.first_name ?? ''} {currentUser?.last_name ?? ''}
              </Typography>
              <Typography
                use="subtitle2"
                tag="h3"
                theme="textSecondaryOnBackground"
                style={{ marginTop: '-1rem' }}
              >
                {currentUser?.profession?.name ?? ''}
              </Typography>
              <Typography
                use="body1"
                tag="div"
                theme="textSecondaryOnBackground"
              >
                {currentUser?.username ?? ''}
              </Typography>
            </div>
            <Card.Actions>
              <Card.ActionButtons>
                <Card.ActionButton
                  danger
                  tag={Link}
                  to={{
                    pathname: `/profile/delete`,
                    state: { previous: location },
                  }}
                >
                  Poista käyttäjätili
                </Card.ActionButton>
              </Card.ActionButtons>
            </Card.Actions>
          </Card>
        </Grid.Cell>
        <Grid.Cell span={8}>
          <Grid.Row>
            <Grid.Cell span={12}>
              <Card theme="secondaryBg">
                <Typography
                  use="headline5"
                  tag="div"
                  style={{ padding: '0.5rem 1rem' }}
                  theme="textSecondaryOnBackground"
                >
                  Yritys
                  <Button
                    icon="add"
                    label={!hasCreatedCompany ? 'Luo yritys' : 'Muokkaa'}
                    tag={Link}
                    to={{
                      pathname: !hasCreatedCompany
                        ? `/profile/company/create`
                        : `/profile/company/edit`,
                      state: { previous: location },
                    }}
                    style={{ float: 'right' }}
                  />
                </Typography>
                <SimpleTable
                  theme="secondaryBg"
                  themeText="textSecondaryOnBackground"
                  items={[
                    ['Nimi:', currentUser?.profile?.company?.name],
                    ['Toimiala:', industry?.name],
                    ['Y-tunnus:', currentUser?.profile?.company?.business_id],
                    ['Paikkakunta:', currentUser?.profile?.company?.city],
                    ['Postinumero:', currentUser?.profile?.company?.zip_code],
                    [
                      'Katuosoite:',
                      currentUser?.profile?.company?.street_address,
                    ],
                    ['Puhelinnumero:', currentUser?.profile?.company?.phone],
                    ['Sähköpostiosoite:', currentUser?.profile?.company?.email],
                    [
                      'Kuvaus:',
                      renderLineBreaks(
                        currentUser?.profile?.company?.description ?? '—',
                      ),
                    ],
                    [
                      'Laskutustiedot:',
                      renderLineBreaks(
                        currentUser?.profile?.company?.billing_information ??
                          '—',
                      ),
                    ],
                  ]}
                />
              </Card>
            </Grid.Cell>
          </Grid.Row>
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

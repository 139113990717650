import React from 'react';
import { Grid, Typography } from 'lib/rmwc';
import TopAppBar from 'components/snippets/TopAppBar';

export function TermsOfServiceText() {
  return (
    <>
      <h1>Palvelusopimuksen yleiset sopimusehdot</h1>

      <h2>Tilaajan ja Toimittajan velvollisuudet</h2>
      <p>
        Toimittaja toimittaa työntekijän Tilaajan käyttöön sopimuksessa sovittua
        vastiketta vastaan. Toimittaja on työntekijän työnantaja, mutta Tilaaja
        vastaa työntekijän työnjohdosta ja valvonnasta sekä työn tekemiseen ja
        järjestelyihin liittyvistä työnantajavelvoitteista koko sen ajan, kun
        työntekijä työskentelee Tilaajan Toimittajalle maksamaa vastiketta
        vastaan.
      </p>
      <p>
        Toimittaja vastaa työntekijän työnantajana työntekijän
        henkilöstökuluista, kuten palkasta, sosiaalikuluista ja lakisääteisistä
        vakuutuksista ja noudattaa suhteessa työntekijään työlainsäädännön ja
        viranomaismääräysten lisäksi kulloinkin sovellettavaksi tulevaa
        työehtosopimusta. Toimittaja huolehtii siitä, että työntekijälle
        annetaan lain ja/tai työehtosopimuksen mukaiset lomat ja vapaat.
      </p>
      <p>
        Tilaajan vastuulla on antaa Toimittajalle oikeat ja riittävät tiedot
        työntekijältä edellytettävästä koulutuksesta, ammattitaidosta ja
        kokemuksesta sekä työturvallisuuden kannalta erityisesti huomioitavista
        työntekijäkohtaisista seikoista. Tilaaja sitoutuu noudattamaan
        työntekijän osalta työlainsäädäntöä, viranomaismääräyksiä ja
        sovellettavaa työehtosopimusta työnsuoritusta koskien (mm. lakisääteiset
        tauot). Tilaaja vastaa työntekijän perehdyttämisestä työhön ennen
        työnteon aloittamista mukaan lukien riittävät tiedot työssä esiintyvistä
        haitta- ja vaaratekijöistä sekä niiden edellyttämistä työ
        suojelutoimenpiteistä sekä siitä, että näitä määräyksiä noudatetaan.
      </p>
      <p>
        Toimittajan työntekijä huolehtii osaltaan oikeista ja turvallisista
        työvarusteista (vaatetus ja suojavarusteet), työtavoista sekä
        käyttäytymisestä ellei Tilaaja toisin ilmoita. Halutessaan Tilaajalla on
        mahdollisuus pyytää työntekijää esiintymään esimerkiksi oman yrityksensä
        tunnuksin varustetuissa työ- ja/tai suojavaatteissa sekä käyttämään
        tarjoamiaan työkaluja ja laitteita.
      </p>
      <p>
        Tilaaja vastaa työhön liittyvien erikoisvarusteiden tai usein vaihtoa
        vaativien kuluvien varusteiden tarjoamisesta työntekijälle.
      </p>

      <h2>Laina-aika ja veloitusperusteet</h2>
      <p>
        Tilaaja antaa arvion työntekijän laina-ajan kestosta, joka on
        maksimissaan Vaihtopenkki-palvelussa vapaaksi ilmoitetun työntekijän
        viimeinen ilmoitettu päivä. Toimittaja veloittaa työntekijän
        laina-ajasta Tilaajaa tehtyjen työtuntien perusteella.
      </p>
      <p>
        Laskutushinta on sovittu tämän sopimuksen hinnoittelu kohdassa. Kyseinen
        tuntihinta ei sisällä arvonlisäveroa eikä lakisääteisiä tai
        työehtosopimusten mukaisia lisiä, ellei toimittaja ole erikseen laskenut
        ja ilmoittanut näitä siihen sisältyväksi. Toimittajalla on oikeus
        laskuttaa edellä mainitut lisäkulut toteutuneiden kulujensa mukaan.
      </p>
      <p>
        Vaihtopenkki-palvelu laskuttaa tilaajalta lopullisen laskun yhteydessä
        erikseen määritellyn välityspalkkion, joka on sidottu tässä sopimuksessa
        sovittuun tilausaikaan (”Sopimuksen ajankohta”). Tilaajalla ei ole
        oikeutta maksaa palkkaa tai muita korvauksia suoraan työntekijälle.
      </p>
      <p>
        Ylityökäytännöistä ja erikoistöistä Tilaajan ja Toimittajan tulee sopia
        yhdessä kirjallisesti erimielisyyksien välttämiseksi ennen kyseisten
        töiden aloittamista. Muutoin menetellään toimialan yleisten normien tai
        työehtosopimusten mukaisesti (myös laskutuksen osalta).
      </p>
      <p>
        Vaihtopenkki-palvelu huolehtii Toimittajan laskun maksamisesta sekä
        vastaa Tilaajalle lähetetyn laskun oikeellisuudesta Vaihtopenkki
        -palvelun käyttöehtojen mukaisesti. Vaihtopenkki selvittää ja korjaa
        mahdolliset osapuolten väliset riidattomat laskutusvirheet.
      </p>

      <h2>Reklamaatiot ja vastuut</h2>
      <p>
        Tilaaja vastaa Toimittajan työntekijän työsuoritteista kolmansien
        osapuolten esittämien virhevastuu- ja takuuvaatimuksien osalta. Tilaaja
        vastaa myös työntekijän kolmannelle osapuolelle aiheuttamasta
        vahingosta, mikäli vahinko tapahtuu Tilaajan lukuun työtä tehtäessä.
        Tilaaja on vastuussa Toimittajan työntekijän työssään käsittelemästä
        omaisuudesta ja/tai huolehdittavaksi annetusta omaisuudesta.
      </p>
      <p>
        Toimittaja ei vastaa vahingoista, joita Tilaajalle mahdollisesti syntyy
        siitä, että työntekijä on estynyt suorittamasta sovittua työtä sairauden
        tai lakisääteisen poissaolon vuoksi.
      </p>
      <p>
        Toimittaja tai Toimittajan työntekijä on korvausvastuussa tahallisesti
        tai vakavaa piittaamattomuutta osoitetulla tavalla aiheutetuista
        vahingoista.
      </p>
      <p>
        Kumpikaan osapuoli ei vastaa toiselle osapuolelle aiheutuneista
        välillisistä vahingoista.
      </p>
      <p>
        Vahingonkorvausta on vaadittava kahdeksan (8) viikon kuluessa siitä, kun
        vahingonkorvauksen perusteena oleva tapahtuma tai virhe havaittiin tai
        se olisi pitänyt havaita, muussa tapauksessa oikeus mahdolliseen
        korvaukseen on menetetty.
      </p>
      <p>
        Mikäli työntekijän ammattitaidossa tai työsuorituksessa ilmenee
        puutteita tai työntekijä ei noudata sovittuja työaikoja tai työtapoja,
        Tilaajan on ilmoitettava tästä välittömästi Toimittajalle, jotta
        Toimittaja voi ryhtyä korjaaviin toimenpiteisiin ja huolehtia
        velvoitteistaan ja oikeuksistaan työnantajana.
      </p>

      <h2>Rekrytointi</h2>
      <p>
        Tilaaja lupautuu olemaan houkuttelematta Toimittajan työntekijää omille
        palkkakirjoilleen korvausvaatimuksen uhalla.
      </p>

      <h2>Ylivoimainen este</h2>
      <p>
        Kumpikaan osapuoli ei vastaa viivästyksistä ja vahingoista, jotka
        johtuvat hänen vaikutusmahdollisuuksiensa ulkopuolella olevasta
        esteestä, jota osapuolen ei kohtuudella voida edellyttää ottaneen
        huomioon palvelusopimuksen tekohetkellä ja jonka seurauksia osapuoli ei
        myöskään kohtuudella olisi voinut välttää.
      </p>
      <p>
        Osapuolen on viipymättä ilmoitettava ylivoimaisesta esteestä tai sen
        lakkaamisesta kirjallisesti toiselle osapuolelle.
      </p>

      <h2>Palvelusopimuksen voimassaolo ja irtisanominen</h2>
      <p>
        Tämä palvelusopimus on tehty Toimittajan ja Tilaajan välillä
        Vaihtopenkki -palvelussa ja tulee voimaan molempien osapuolten
        hyväksyttyä sopimuksen Vaihtopenkki -palvelussa.
      </p>
      <p>
        Sopimus päättyy, kun työntekijän työskentely Tilaajan lukuun päättyy.
      </p>
      <p>
        Mikäli Tilaaja laiminlyö tämän palvelusopimuksen mukaisen
        maksuvelvollisuutensa tai jompikumpi osapuolista muutoin olennaisesti
        rikkoo palvelusopimuksen ehtoja, toisella osapuolella on oikeus purkaa
        palvelusopimus päättymään välittömästi.
      </p>
      <p>
        Jos toista osapuolta haetaan konkurssiin tai yrityssaneeraukseen tai se
        asetetaan selvitystilaan, toisella osapuolella on oikeus purkaa
        palvelusopimus välittömin vaikutuksin.
      </p>
      <p>
        Mikäli tämä palvelusopimus halutaan päättää ennen sovittua määräaikaa
        Tilaajasta tai Toimittajasta johtuvasta perustellusta syystä, tulee se
        tehdä palvelun käyttöehtojen mukaisesti.
      </p>

      <h2>Tietosuoja</h2>
      <p>
        Palvelunsopimuksen toteuttaminen sekä näiden ehtojen mukaisten
        velvollisuuksien täyttäminen edellyttää henkilötietojen käsittelemistä
        sekä niiden luovuttamista osapuolten kesken. Kumpikin osapuoli
        käsittelee henkilötietoja itsenäisenä rekisterinpitäjänä omaan lukuunsa
        ja vastaa omalta osaltaan henkilötietojen käsittelystä soveltuvan
        lainsäädännön mukaisesti. Mikäli osapuoli käsittelee henkilötietoja
        toisen osapuolen lukuun henkilötietojen käsittelijänä, sitoutuu
        käsittelijä noudattamaan tietosuoja-asetuksen ja kansallisen
        lainsäädännön vaatimuksia, ja osapuolet sitoutuvat sopimaan tällaisesta
        käsittelystä erikseen.
      </p>
      <p>
        Kumpikin osapuoli informoi rekisteröityjä asianmukaisella ja riittävällä
        tavalla luovutusta edeltävästä henkilötietojen käsittelystään,
        henkilötietojen luovutuksesta ja/tai luovutuksen jälkeisestä
        henkilötietojen käsittelystään. Kumpikin osapuoli sitoutuu käsittelemään
        toisen osapuolen sille luovuttamia henkilötietoja ainoastaan
        sopimuksessa kuvattujen velvoitteiden täyttämiseksi, ellei laista muuta
        johdu.
      </p>

      <h2>Sovellettava laki ja oikeuspaikka</h2>
      <p>Palvelusopimukseen sovelletaan Suomen lakia.</p>
      <p>
        Osapuolet pyrkivät ratkaisemaan palvelusopimuksesta johtuvat
        erimielisyydet neuvotteluteitse. Mikäli osapuolet eivät pääse
        neuvotteluissa yksimielisyyteen, riidat ratkaistaan ensimmäisenä
        oikeusasteena Toimittajan kotipaikan käräjäoikeudessa.
      </p>
    </>
  );
}

export default function TermsOfService() {
  return (
    <React.Fragment>
      <TopAppBar
        navigationMenu="arrow"
        navigationMenuUrl="/"
        progress={false}
      ></TopAppBar>

      <Grid style={{ maxWidth: 1200 }}>
        <Grid.Cell span={12} style={{ justifySelf: 'center' }}>
          <Typography className="mdc-theme--on-primary">
            <TermsOfServiceText />
          </Typography>
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

import { TextField } from 'lib/rmwc';
import { User } from 'models/User';
import { Form } from 'orm';

export default class RegisterForm extends Form {
  static model = User;

  static onSubmit = ({ data, actions }) => {
    const [isValid, errors] = this.validate(data);
    if (!isValid) return errors;
    return actions.post({
      url: `${process.env.REACT_APP_AUTH_URL}/registration/`,
      data: {
        first_name: data.first_name,
        last_name: data.last_name,
        email: data.email,
        username: data.email,
        password1: data.password1,
        password2: data.password2,
      },
    });
  };

  static validate = (data) => {
    if (data.password1 === data.password2) return [true];
    else return [false, { error: "Passwords doesn't match." }];
  };

  static fields = {
    first_name: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Etunimi *',
        outlined: true,
        fullwidth: true,
      },
    }),
    last_name: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Sukunimi *',
        outlined: true,
        fullwidth: true,
      },
    }),
    email: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Sähköpostiosoite *',
        outlined: true,
        fullwidth: true,
      },
    }),
    password1: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Salasana *',
        type: 'password',
        outlined: true,
        fullwidth: true,
      },
    }),
    password2: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Salasana uudestaan *',
        type: 'password',
        outlined: true,
        fullwidth: true,
      },
    }),
  };
}

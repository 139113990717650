import { useLocationSearch } from 'hooks';
import React from 'react';

import TopAppBar from './TopAppBar';

export default function TopAppBarPagination({
  next = false,
  previous = false,
  pageParameter = 'page',
}) {
  const [search, setSearch] = useLocationSearch();
  const turnLeft = () =>
    setSearch({
      [pageParameter]: Math.max(1, Number(search[pageParameter] || 0) - 1),
    });
  const turnRight = () =>
    setSearch({
      [pageParameter]: Number(search[pageParameter] || 0) + 1,
    });

  return (
    <>
      <TopAppBar.Section>
        <TopAppBar.ActionItem
          disabled={!previous}
          theme={
            !previous ? 'textDisabledOnBackground' : 'textSecondaryOnBackground'
          }
          icon="keyboard_arrow_left"
          onClick={turnLeft}
        />
      </TopAppBar.Section>
      <TopAppBar.Section>
        <TopAppBar.ActionItem
          disabled={!next}
          theme={
            !next ? 'textDisabledOnBackground' : 'textSecondaryOnBackground'
          }
          icon="keyboard_arrow_right"
          onClick={turnRight}
        />
      </TopAppBar.Section>
    </>
  );
}

import { Model } from 'orm';

import { Company } from './Company';
import { Competency } from './Competency';
import { Industry } from './Industry';
import { Licence } from './Licence';
import { Profession } from './Profession';
import { Province } from './Province';

export class Employee extends Model {
  static modelName = 'Employee';

  static fields = {
    id: new this.CharField(),
    name: new this.CharField({ maxLength: 50 }),
    picture: new this.CharField(),
    description: new this.CharField(),
    experience_years: new this.IntegerField(),
    cost_per_hour: new this.CharField(),
    is_available: new this.BooleanField(),
    available_from: new this.CharField(),
    available_to: new this.CharField(),
    province: new this.ForeignKey(Province, { relatedName: 'province' }),
    competencies: new this.ManyToManyField(Competency),
    licences: new this.ManyToManyField(Licence),
    profession: new this.ForeignKey(Profession, { relatedName: 'employee' }),
    company: new this.ForeignKey(Company, { relatedName: 'employee' }),
    industry: new this.ForeignKey(Industry, { relatedName: 'industry' }),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/employees/:id`,
    persist: false,
  };

  static methods = {
    getCostIncludingCommission(contract) {
      const commissionInDecimal = contract?.getCommissionInDecimal?.() ?? 0;
      const costPerHour = Number(this.cost_per_hour || 0);
      return costPerHour * (commissionInDecimal + 1);
    },
  };

  reduce(payload) {
    if (payload.results) {
      const { results = null, ...meta } = payload;
      super.meta = meta;
      super.reduce(results);
    } else {
      super.reduce(payload);
    }
  }
}

export class EmployeeAvailability extends Model {
  static modelName = 'EmployeeAvailability';

  static fields = {
    id: new this.CharField(),
    is_available: new this.BooleanField(),
    overlapping_contracts: new this.ArrayField(),
    status: new this.CharField(),
    start_time: new this.CharField(),
    end_time: new this.CharField(),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/employees/:id/check_availability/`,
    persist: false,
  };
}

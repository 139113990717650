import { Grid, Tooltip } from 'lib/rmwc';
import React from 'react';

export default function BasicForm({ form }) {
  return (
    <Grid style={{ width: '100%', padding: 0 }}>
      {(form?.fields || [])
        .filter((props) => !props.hidden)
        .map((props, index) => (
          <CustomTextField key={index} form={form} {...props} />
        ))}
    </Grid>
  );
}

function CustomTextField(props) {
  const { Field, span, form, theme, ...rest } = props;
  const error = form?.errors?.[props.name];
  return (
    <Grid.Cell span={span}>
      <Tooltip
        align="bottomLeft"
        content={error || ''}
        showArrow
        open={!!error}
      >
        <Field form={form} theme={theme} invalid={!!error} {...rest} />
      </Tooltip>
    </Grid.Cell>
  );
}

import { Model } from 'orm';

import { Profile } from './Profile';

export class User extends Model {
  static modelName = 'User';

  static fields = {
    id: new this.CharField(),
    email: new this.CharField(),
    username: new this.CharField(),
    first_name: new this.CharField(),
    last_name: new this.CharField(),
    profile: new this.OneToOneField(Profile, { relatedName: 'user' }),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/account/`,
    persistMeta: true,
  };

  reduce(payload) {
    const { key, detail, ...rest } = payload;
    if (key) {
      super.meta = { ...super.meta, key };
    }
    if (detail) {
      super.meta = { ...super.meta, detail };
    }
    super.reduce(rest);
  }
}

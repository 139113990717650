import { SessionContext } from 'App';
import { CreateContractForEmployeeDialog } from 'components/dialogs/Contract';
import {
  DeleteEmployeeDialog,
  EditEmployeeDialog,
} from 'components/dialogs/Employee';
import EmployeeDetailsGrid from 'components/snippets/EmployeeDetailsGrid';
import TopAppBar from 'components/snippets/TopAppBar';
import { Button } from 'lib/rmwc';
import { useNoUndefinedEffect } from 'orm';
import React, { useContext } from 'react';
import { Link, Route, useLocation, useParams } from 'react-router-dom';

export default function EmployeeDetail() {
  const location = useLocation();
  const { id: idStr } = useParams();
  const id = Number(idStr);

  const { Employee, Contract, ContractTemplate, CurrentUser, headers } =
    useContext(SessionContext);
  const employee = Employee.get({ id });
  const contracts = Contract.all();
  const contractTemplate = ContractTemplate.first();

  const belongsToCurrentUser =
    !!employee?.company?.id &&
    employee?.company?.id === CurrentUser.currentUser?.profile?.company?.id;

  useNoUndefinedEffect(() => {
    Employee.actions.get({
      headers,
      id,
      params: { include: 'employee_details' },
    });
  }, [id]);

  useNoUndefinedEffect(() => {
    Contract.delete();
    if (belongsToCurrentUser) {
      Contract.actions.get({
        headers,
        params: {
          employee__id: id,
          accepted: true,
          expired: false,
          include: ['contract_details'],
        },
      });
    }
  }, [id, belongsToCurrentUser]);

  useNoUndefinedEffect(() => {
    if (!contractTemplate) {
      ContractTemplate.actions.get({ headers });
    }
  }, [contractTemplate]);

  const previous = location?.state?.previous || { pathname: '/employees' };

  return (
    <React.Fragment>
      <Route path="/employee/:id/edit">
        <EditEmployeeDialog employee={employee} />
      </Route>
      <Route path="/employee/:id/delete">
        <DeleteEmployeeDialog employee={employee} />
      </Route>
      <Route path="/employee/:id/contracts/create">
        <CreateContractForEmployeeDialog employee={employee} />
      </Route>

      <TopAppBar
        navigationMenu="arrow"
        navigationMenuTo={previous}
        progress={!employee}
      >
        <TopAppBar.Section>
          {belongsToCurrentUser ? (
            <>
              <Button
                icon="delete"
                label="Poista"
                tag={Link}
                to={{
                  pathname: `/employee/${id}/delete`,
                }}
              />
              <Button
                icon="add"
                label="Muokkaa"
                tag={Link}
                to={{
                  pathname: `/employee/${id}/edit`,
                  state: { previous: location },
                }}
              />
            </>
          ) : (
            <>
              <Button
                icon="add"
                label="Tee sopimus"
                tag={Link}
                to={{
                  pathname: `/employee/${id}/contracts/create`,
                  state: {
                    previous: location,
                  },
                }}
              />
            </>
          )}
        </TopAppBar.Section>
      </TopAppBar>
      <EmployeeDetailsGrid
        employee={employee}
        contracts={contracts}
        contractTemplate={belongsToCurrentUser ? null : contractTemplate}
      />
    </React.Fragment>
  );
}

import { Model } from 'orm';
import { Industry } from './Industry';

export class Competency extends Model {
  static modelName = 'Competency';
  static modelNamePlural = 'Competencies';

  static fields = {
    id: new this.CharField(),
    name: new this.CharField({ maxLength: 50 }),
    industries: new this.ManyToManyField(Industry),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/competencies/:id`,
  };

  reduce(payload) {
    if (payload.results) {
      const { results = null, ...meta } = payload;
      super.meta = meta;
      super.reduce(results);
    } else {
      super.reduce(payload);
    }
  }
}

import { Button, Card, List, Typography } from 'lib/rmwc';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { renderLineBreaks } from 'renderUtils';

import { SimpleTable } from '../SimpleTable';

export function PricingCard({
  contract,
  theme = '',
  themeText = '',
  themeButton = '',
  outlined = false,
  editable = false,
}) {
  const includesCommission = !!contract?.brokerage_commision;
  const costIncludingCommission =
    contract?.getCostIncludingCommission() ||
    contract?.employee?.getCostIncludingCommission?.(contract) ||
    0;
  const location = useLocation();

  return (
    <Card outlined={outlined} theme={theme}>
      <Typography
        use="subtitle1"
        tag="div"
        style={{ padding: '0.5rem 1rem' }}
        theme={themeText}
      >
        Hinnoittelu
        {editable && (
          <Button
            tag={Link}
            to={{
              pathname: location.pathname + '/fill',
              state: { previous: location },
            }}
            theme={themeButton}
            style={{ float: 'right', height: 28 }}
          >
            Täytä tietoja
          </Button>
        )}
      </Typography>
      <List.Divider />
      <SimpleTable
        theme={theme}
        themeText={themeText}
        items={[
          [
            includesCommission
              ? 'Tuntiveloitushinta, sisältäen välityspalkkion (alv. 0%):'
              : 'Tuntiveloitushinta (alv. 0%):',
            costIncludingCommission
              ? `${costIncludingCommission.toFixed(2)} €/h`
              : undefined,
          ],
          [
            'Muut hinta- ja lisätiedot *):',
            renderLineBreaks(contract?.details || '-'),
          ],
        ]}
      />
      <div style={{ padding: '1rem' }}>
        <Typography use="subtitle2" tag="span" theme={themeText}>
          *) Lakisääteiset ja työehtosopimusten mukaiset lisät voidaan laskuttaa
          tilaajalta ilman erillistä sopimusta, mikäli ilmoitettu
          perustuntihinta ei näitä sisällä. Sama koskee myös teetettyjä
          ylitöitä.
        </Typography>
      </div>
    </Card>
  );
}

import { SessionContext } from 'App';
import { JobNoticeListItem } from 'components/snippets/JobNoticeListItem';
import { useDeepCompareEffect, useNoUndefinedEffect } from 'orm';
import React, { useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

import ResourceList from './ResourceList';

export default function JobNoticeList({
  page,
  pageSize,
  onChange,
  usePagination = true,
  search = {},
  style = {},
  emptyText = '',
  title = 'Työtehtäväilmoitukset',
  subtitle = '',
  createLabel = 'Lisää työtehtävä',
}) {
  const [progress, setProgress] = useState(false);
  const location = useLocation();

  const { JobNotice, headers } = useContext(SessionContext);

  useDeepCompareEffect(() => {
    JobNotice.delete();
  }, []);

  useNoUndefinedEffect(() => {
    (async () => {
      setProgress(true);
      JobNotice.delete();
      await JobNotice.actions.get({
        headers,
        params: {
          ...search,
          include: 'jobnotice_details',
          page: page,
          page_size: pageSize,
        },
      });
      setProgress(false);
    })();
  }, [page, pageSize, search]);

  useNoUndefinedEffect(() => {
    onChange({ ...search, page, pageSize, progress });
  }, [progress]);

  const jobnotices = JobNotice.all();

  function onPageChange(direction) {
    onChange({ ...search, page: Number(page) + direction, pageSize, progress });
  }

  return (
    <React.Fragment>
      <ResourceList
        page={page}
        onPageChange={onPageChange}
        meta={usePagination && { ...JobNotice.meta, page, pageSize }}
        progress={progress}
        title={title}
        subtitle={subtitle}
        createLabel={createLabel}
        createPathname="/home/create-jobnotice"
        style={style}
        emptyText={emptyText}
        resources={jobnotices}
        render={(resources) =>
          resources.map((jobnotice) => (
            <JobNoticeListItem
              key={jobnotice.id}
              jobnotice={jobnotice}
              to={`/jobnotice/${jobnotice.id}`}
              location={location}
            />
          ))
        }
      />
    </React.Fragment>
  );
}

import { Menu, TextField } from 'lib/rmwc';
import { useDeepCompareEffect } from 'orm';
import React, { createRef, useState } from 'react';

export function Autocomplete({ multiple = false, ...rest }) {
  return multiple ? AutocompleteMultiple(rest) : AutocompleteSingle(rest);
}

function AutocompleteSingle({
  options,
  onChange,
  value: selectedValue,
  clearable,
  ...props
}) {
  options = options ?? [];

  const ref = createRef();
  const [open, setOpen] = useState(false);
  const [minWidth, setMinWidth] = useState(0);
  const [filterText, setFilterText] = useState('');
  const [selected, setSelected] = useState();

  const filterLower = filterText.toLowerCase();
  const filteredOptions =
    !selected || selected.label === filterText
      ? options
      : options.filter(({ label }) =>
          label.toLowerCase().includes(filterLower),
        );

  function onTextInput(text) {
    setFilterText(text);
    if (!text) {
      onChange({ target: { value: '' } });
      setSelected(undefined);
    }
  }

  function clearInput(e) {
    e.preventDefault();
    e.stopPropagation();
    onTextInput('');
  }

  function onSelect(e) {
    const selected = options.find(({ value }) => value === e.detail.item.value);
    if (selected) {
      setSelected(selected);
    }
  }

  function onClose() {
    if (open) {
      setOpen(false);
    }
  }

  // order of effects matters

  useDeepCompareEffect(() => {
    if (options.length > 0 && selected) {
      setFilterText(selected?.label ?? '');
      const value = selected?.value ?? '';
      onChange({ target: { value } });
    }
  }, [selected, options]);

  useDeepCompareEffect(() => {
    if (options.length > 0 && selectedValue && selectedValue !== selected) {
      if (!selected) {
        const selectedOption = options.find(
          ({ value }) => value.toString() === selectedValue.toString(),
        );
        if (selectedOption) {
          // initial select
          setSelected(selectedOption);
        }
      } else {
        const selectedOption = options.find(
          ({ value }) => value === selected.value,
        );
        if (!selectedOption) {
          // options changed, old option is gone
          onTextInput('');
        }
      }
    }
  }, [selectedValue, selected, options]);

  const disabled = options.length === 0;

  return (
    <Menu.SurfaceAnchor>
      <Menu
        open={open}
        focusOnOpen={false}
        onOpen={() => {
          setMinWidth(ref?.current?.clientWidth ?? 0);
        }}
        onClose={onClose}
        onSelect={onSelect}
        anchorCorner="bottomStart"
        style={{ maxHeight: 198, minWidth }}
        renderToPortal
      >
        {filteredOptions.length === 0 && (
          <Menu.Item style={{ fontStyle: 'italic' }} disabled>
            Ei tuloksia.
          </Menu.Item>
        )}
        {filteredOptions.map(({ value, label }) => (
          <Menu.Item
            key={value}
            activated={`${value}` === `${selectedValue}`}
            value={value}
          >
            {label}
          </Menu.Item>
        ))}
      </Menu>
      <TextField
        ref={ref}
        {...props}
        disabled={props?.disabled || disabled}
        onClick={() => setOpen(true)}
        value={filterText}
        onChange={(e) => onTextInput(e.target.value)}
        autoComplete="off"
        placeholder={open ? 'Hae...' : undefined}
        floatLabel={open ? true : undefined}
        trailingIcon={
          clearable && filterText
            ? {
                icon: 'close',
                tabIndex: 0,
                onClick: clearInput,
              }
            : {
                icon: open ? 'arrow_drop_up' : 'arrow_drop_down',
                tabIndex: 0,
                onClick: () => setOpen(!open),
              }
        }
      />
    </Menu.SurfaceAnchor>
  );
}

function AutocompleteMultiple({
  options = [],
  onChange = () => {},
  value: menuValues = [],
  clearable,
  ...props
}) {
  const ref = createRef();
  const [open, setOpen] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [minWidth, setMinWidth] = useState(0);

  const filterLower = filterText.toLowerCase();
  const filteredOptions = options.filter(
    ({ label }) => !filterText || label.toLowerCase().includes(filterLower),
  );

  function onTextInput(text) {
    setFilterText(text);
  }

  function clearInput(e) {
    e.preventDefault();
    e.stopPropagation();
    setFilterText('');
    onChange({ target: { value: [] } });
  }

  function onSelect(value, checked) {
    if (checked) {
      onChange({ target: { value: [...menuValues, value].sort() } });
    } else {
      onChange({
        target: { value: menuValues.filter((v) => v !== value) },
      });
    }
    setFilterText('');
  }

  function onClose() {
    if (open) {
      setOpen(false);
      setFilterText('');
    }
  }

  const label =
    menuValues.length > 1
      ? `Valittu: ${menuValues.length}`
      : options.find((o) => o.value === menuValues[0])?.label || '';
  const placeholderText = `Hae...${
    menuValues.length > 0 ? ` (Valittu: ${menuValues.length})` : ''
  }`;

  const disabled = options.length === 0;

  return (
    <Menu.SurfaceAnchor>
      <Menu
        open={open}
        focusOnOpen={false}
        onOpen={() => {
          setMinWidth(ref?.current?.clientWidth ?? 0);
        }}
        onSelect={onSelect}
        onClose={onClose}
        anchorCorner="bottomStart"
        style={{ maxHeight: 198, minWidth }}
        renderToPortal
      >
        {filteredOptions.length === 0 && (
          <Menu.Item style={{ fontStyle: 'italic' }} disabled>
            Ei tuloksia.
          </Menu.Item>
        )}
        {filteredOptions.map(({ value, label }) => {
          const checked = menuValues.includes(value);
          return (
            <Menu.Item
              key={value}
              activated={checked}
              onClick={(e) => {
                e.stopPropagation();
                onSelect(value, !checked);
              }}
            >
              {label}
            </Menu.Item>
          );
        })}
      </Menu>
      <TextField
        ref={ref}
        {...props}
        disabled={props?.disabled || disabled}
        onClick={() => setOpen(!open)}
        value={open ? filterText : label}
        onChange={(e) => onTextInput(e.target.value)}
        autoComplete="off"
        placeholder={open ? placeholderText : undefined}
        floatLabel={open ? true : undefined}
        trailingIcon={
          clearable && menuValues.length > 0
            ? {
                icon: 'close',
                tabIndex: 0,
                onClick: clearInput,
              }
            : {
                icon: open ? 'arrow_drop_up' : 'arrow_drop_down',
                tabIndex: 0,
                onClick: () => setOpen(!open),
              }
        }
      ></TextField>
    </Menu.SurfaceAnchor>
  );
}

import { SearchJobNoticesForm } from 'forms/JobNotice';
import React from 'react';

import { SearchDialog } from './SearchDialog';

export function SearchJobNoticesDialog() {
  return (
    <SearchDialog
      formClass={SearchJobNoticesForm}
      pathname={'/jobnotices'}
      title={'Hae työtehtäviä'}
    />
  );
}

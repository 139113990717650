import { Model } from 'orm';

import { Company } from './Company';
import { Industry } from './Industry';
import { Profession } from './Profession';
import { Province } from './Province';

export class JobNotice extends Model {
  static modelName = 'JobNotice';
  static modelNamePlural = 'JobNotices';

  static fields = {
    id: new this.CharField(),
    company: new this.ForeignKey(Company, { relatedName: 'company' }),
    industry: new this.ForeignKey(Industry, { relatedName: 'industry' }),
    profession: new this.ForeignKey(Profession, { relatedName: 'profession' }),
    province: new this.ForeignKey(Province, { relatedName: 'province' }),
    start_time: new this.CharField(),
    end_time: new this.CharField(),
    details: new this.CharField(),
    location: new this.CharField(),
    created_at: new this.CharField(),
    updated_at: new this.CharField(),
  };
  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/jobnotices/:id`,
    persist: false,
  };

  reduce(payload) {
    if (payload.results) {
      const { results = null, ...meta } = payload;
      super.meta = meta;
      super.reduce(results);
    } else {
      super.reduce(payload);
    }
  }
  parse(payload) {
    const { Company, Province } = this.session;
    return super.parse({
      ...payload,
      province: Province.parse(payload?.province),
      company: Company.parse(payload?.company),
    });
  }
}

import { IndustryAutocompleteSelect } from 'components/forms/fields/Select';
import { TextField } from 'lib/rmwc';
import { Company } from 'models/Company';
import { Form } from 'orm';

export class CreateCompanyForm extends Form {
  static model = Company;

  static onSubmit = this.postSubmit;

  static fields = {
    id: new this.ID(),
    name: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Yrityksen nimi *',
        outlined: true,
        fullwidth: true,
      },
    }),
    industry: new this.CharField(IndustryAutocompleteSelect, {
      extra: {
        span: 6,
        label: 'Toimiala',
        outlined: true,
        fullwidth: true,
      },
    }),
    business_id: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Y-tunnus',
        outlined: true,
        fullwidth: true,
      },
    }),
    city: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Paikkakunta',
        outlined: true,
        fullwidth: true,
      },
    }),
    zip_code: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Postinumero',
        outlined: true,
        fullwidth: true,
      },
    }),
    street_address: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Katuosoite',
        outlined: true,
        fullwidth: true,
      },
    }),
    phone: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Puhelinnumero',
        outlined: true,
        fullwidth: true,
      },
    }),
    email: new this.CharField(TextField, {
      extra: {
        span: 6,
        label: 'Sähköpostiosoite',
        outlined: true,
        fullwidth: true,
      },
    }),
    description: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Kuvaus',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
    billing_information: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Laskutustiedot',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
  };
}

export class EditCompanyForm extends CreateCompanyForm {
  static onSubmit = this.putSubmit;
}

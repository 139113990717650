import { useRef } from 'react'
export class Session {
  constructor(models) {
    for (let key in models) {
      this[key] = models[key]
      this[key].setSession(this)
    }
  }
}

export const useSession = (models) => {
  const session = useMemoOnce(() => new Session(models))
  return { ...session }
}

const useMemoOnce = (factory) => {
  const ref = useRef()
  if (!ref.current) {
    ref.current = factory()
  }
  return ref.current
}

import { DateRangeList } from 'components/forms/fields/DateRangeList';
import {
  CompanyAutocompleteSelect,
  EmployeeAutocompleteSelect,
  MyEmployeeAutocompleteSelect,
} from 'components/forms/fields/Select';
import { TextField } from 'lib/rmwc';
import { Contract, RejectedContract } from 'models/Contract';
import { Form } from 'orm';

export class CreateContractForm extends Form {
  static model = Contract;

  static onSubmit = this.postSubmit;

  static fields = {
    id: new this.ID(),
    created_by: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    receiving_company: new this.CharField(CompanyAutocompleteSelect, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    providing_company: new this.CharField(CompanyAutocompleteSelect, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    employee: new this.CharField(EmployeeAutocompleteSelect, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    start_time: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Aloituspäivämäärä *',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
    end_time: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Päättymispäivämäärä *',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
    location: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Toimipiste tai työkohteen osoite *',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
    details: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Muut hinta- ja lisätiedot',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
    reserved: new this.CharField(DateRangeList, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Varattuna aikavälillä',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
  };
}

export class CreateJobNoticeContractForm extends Form {
  static model = Contract;

  static onSubmit = this.postSubmit;

  static fields = {
    id: new this.ID(),
    created_by: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    receiving_company: new this.CharField(CompanyAutocompleteSelect, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    providing_company: new this.CharField(CompanyAutocompleteSelect, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    employee: new this.CharField(MyEmployeeAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Työntekijä *',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
        clearable: true,
      },
    }),
    details: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Muut hinta- ja lisätiedot',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
    start_time: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    end_time: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    location: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
  };
}

export class AcceptContractForm extends CreateContractForm {
  static onSubmit = this.putSubmit;

  static fields = {
    id: new this.ID(),
    accepted_by: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
    accepted: new this.BooleanField(TextField, {
      maxLength: 500,
      extra: {
        hidden: true,
      },
    }),
  };
}

export class RejectContractForm extends Form {
  static model = RejectedContract;

  static onSubmit = ({ data, actions, headers }) => {
    return actions.post({ headers, id: data.id, data });
  };

  static fields = {
    id: new this.ID(),
    reason: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Hylkäämisperuste (Lähetetään sopimuksen ehdottajalle)',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
  };
}

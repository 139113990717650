import React, { useContext, useEffect, useState } from 'react';
import { SessionContext } from 'App';
import { Button, Grid, Typography } from 'lib/rmwc';
import TopAppBar from 'components/snippets/TopAppBar';
import { useForm } from 'orm';
import BasicForm from 'components/forms/BasicForm';
import AccountDeleteRequestForm from 'forms/AccountDeleteRequest';

export default function AccountDeletion() {
  const session = useContext(SessionContext);
  const form = useForm(session, AccountDeleteRequestForm);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    form.pristine = true;
  }, [form]);

  const onSubmit = async () => {
    const response = await form.onSubmit();
    if (!response?.errors) {
      form.reset();
      setSubmitted(true);
    }
  };

  return (
    <React.Fragment>
      <TopAppBar
        navigationMenu="arrow"
        navigationMenuUrl="/"
        progress={false}
      ></TopAppBar>

      <Grid style={{ maxWidth: 640 }}>
        <Grid.Cell span={12} style={{ justifySelf: 'center' }}>
          <Typography theme="onPrimary">
            <h1>Vaihtopenkki-tunnuksen poistaminen</h1>

            <p>
              Tämän lomakkeen avulla voit pyytää Vaihtopenkki-tunnuksesi
              poistamista, mikäli et voi tai halua kirjautua sisään palveluun.
              Huomioi, että kirjautumalla sisään voit kuitenkin halutessasi
              poistaa oman tunnuksesi välittömästi.
            </p>

            <p>
              Pyynnön lähetettyäsi voimme poistaa tunnuksesi antamiesi tietojen
              perusteella. Ole mahdollisimman tarkka lähettämiesi tietojen
              kanssa, jotta voimme olla varmoja, että pyyntö tulee oikealta
              taholta.
            </p>
          </Typography>
        </Grid.Cell>
        <Grid.Cell
          span={12}
          style={{ justifySelf: 'center', padding: '1rem', width: '100%' }}
          theme="surface"
        >
          {!submitted ? (
            <>
              <Grid.Row>
                <Grid.Cell span={12}>
                  <BasicForm form={form} />
                </Grid.Cell>
              </Grid.Row>
              <Grid.Row>
                <Grid.Cell
                  span={12}
                  style={{ justifySelf: 'end', paddingTop: '1rem' }}
                >
                  <Button
                    disabled={form.pristine || form.submitting}
                    action="submit"
                    onClick={onSubmit}
                  >
                    Pyydä tunnuksen poistamista
                  </Button>
                </Grid.Cell>
              </Grid.Row>
            </>
          ) : (
            <Grid.Row>
              <Grid.Cell span={12}>Pyyntö lähetetty.</Grid.Cell>
            </Grid.Row>
          )}
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

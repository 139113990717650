import React from 'react';
import { Card, List, Typography, Icon } from 'lib/rmwc';

export function PaymentConditionCard({
  theme = '',
  themeText = '',
  outlined = false,
}) {
  return (
    <Card outlined={outlined} theme={theme}>
      <Typography
        use="subtitle1"
        tag="div"
        style={{ padding: '0.5rem 1rem' }}
        theme={themeText}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Icon
            icon={{ icon: 'error_outline', size: 'medium' }}
            style={{ paddingRight: '0.5rem' }}
          />
          <span>Maksuehto</span>
        </div>
      </Typography>
      <List.Divider />
      <div style={{ padding: '1rem' }}>
        <Typography use="body1" tag="span" theme={themeText}>
          Toimittaja laskuttaa Vaihtopenkki-palvelua sopimuksessa sovitun
          hinnoittelun mukaisesti 21 vuorokauden maksuehdolla. Vaihtopenkki
          (ProChoice Oy) laskuttaa Tilaajaa Toimittajan laskun ja sopimuksessa
          määritellyn välityspalkkion mukaisesti 14 vuorokauden maksuehdolla.
        </Typography>
      </div>
    </Card>
  );
}

import { Grid } from 'lib/rmwc';
import React from 'react';

import { CompanyCard } from './CompanyCard';
import { ContractDetailsCard } from './ContractDetailsCard';
import { PaymentConditionCard } from './PaymentConditionCard';
import { PricingCard } from './PricingCard';
import { TermsOfServiceCard } from './TermsOfServiceCard';

export function LeaseContractTemplate({
  contract,
  editable = false,
  outlined = false,
  theme = 'surfaceBg',
  themeText = 'onSurface',
  themeButton = 'textPrimaryOnLight',
  style = {},
}) {
  const receivingPerson = contract?.getReceivingPerson?.() ?? null;
  const providingPerson = contract?.getProvidingPerson?.() ?? null;
  return (
    <Grid style={style}>
      <Grid.Row style={{ gridGap: 16 }}>
        <Grid.Cell span={6}>
          <CompanyCard
            header="Tilaaja"
            theme={theme}
            themeText={themeText}
            outlined={outlined}
            company={contract?.receiving_company}
            person={receivingPerson}
          />
        </Grid.Cell>
        <Grid.Cell span={6}>
          <CompanyCard
            header="Toimittaja"
            theme={theme}
            themeText={themeText}
            outlined={outlined}
            company={contract?.providing_company}
            person={providingPerson}
          />
        </Grid.Cell>
        <Grid.Cell span={12}>
          <ContractDetailsCard
            theme={theme}
            themeText={themeText}
            themeButton={themeButton}
            outlined={outlined}
            contract={contract}
            editable={editable}
          />
        </Grid.Cell>
        <Grid.Cell span={12}>
          <PricingCard
            theme={theme}
            themeText={themeText}
            themeButton={themeButton}
            outlined={outlined}
            contract={contract}
            editable={editable}
          />
        </Grid.Cell>
        <Grid.Cell span={12}>
          <PaymentConditionCard
            theme={theme}
            themeText={themeText}
            outlined={outlined}
          />
        </Grid.Cell>
        <Grid.Cell span={12}>
          <TermsOfServiceCard
            theme={theme}
            themeButton={themeButton}
            outlined={outlined}
          />
        </Grid.Cell>
      </Grid.Row>
    </Grid>
  );
}

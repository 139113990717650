import { TextField } from 'lib/rmwc';
import { AccountDelete } from 'models/AccountDelete';
import { Form } from 'orm';

export default class AccountDeleteRequestForm extends Form {
  static model = AccountDelete;
  static onSubmit = ({ data, actions, headers }) => {
    return actions.post({ headers, data });
  };

  static fields = {
    email: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Sähköpostiosoite',
        outlined: true,
        fullwidth: true,
      },
    }),
    company: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Yrityksen nimi',
        outlined: true,
        fullwidth: true,
      },
    }),
    info: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Lisätietoja (vapaaehtoinen)',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
  };
}

import React from 'react';
import { Grid, Typography } from 'lib/rmwc';
import TopAppBar from 'components/snippets/TopAppBar';

export default function PrivacyPolicy() {
  return (
    <React.Fragment>
      <TopAppBar
        navigationMenu="arrow"
        navigationMenuUrl="/"
        progress={false}
      ></TopAppBar>

      <Grid style={{ maxWidth: 1200 }}>
        <Grid.Cell span={12} style={{ justifySelf: 'center' }}>
          <Typography className="mdc-theme--on-primary">
            <h1>Tietosuojaseloste</h1>

            <p>
              <b>Päiväys</b> 20.07.2020
            </p>

            <p>
              ProChoice Oy toimii rekisterin pitäjänä yritysasiakkaidensa
              yhteyshenkilöiden ja edustajien (”käyttäjät”) henkilötiedoille.
              Käsittelemme henkilötietoja kulloinkin voimassa olevan
              lainsäädännön mukaisesti. Tässä tietosuojaselosteessa kerromme
              miten ja mihin tarkoituksiin käsittelemme käyttäjien
              henkilötietoja.
            </p>

            <h2>Rekisterin ylläpitäjä:</h2>
            <p>ProChoice Oy, Vaihtopenkki (Y-tunnus: 3113435-1)</p>
            <p>Metsäkansa 63, 37850 METSÄKANSA</p>
            <h2>Yhteyshenkilö:</h2>
            <p>Ari Jääskeläinen, ari.jaaskelainen@vaihtopenkki.net</p>
            <p>Puhelin: +358 (0) 40 535 6677</p>

            <h2>Palvelussa käsiteltävät tiedot ja niiden käyttö</h2>
            <p>
              Palvelussa vierailevien käyttäjien yritys- ja henkilötietoja
              kerätään, käytetään ja käsitellään asiakassuhteiden hoitamista,
              palveluiden tuottamista, suoramarkkinointia, etämyyntiä sekä
              asiakasyhteydenpitoa ja asiakastapahtumia varten. Tämän lisäksi
              tietoja voidaan käyttää liiketoiminnan kehittämistarkoituksia ja
              tilastollisia vertailuja varten sekä lainsäädännöstä tulevien
              velvoitteiden noudattamiseksi.
            </p>

            <h1>Käsiteltävät henkilötiedot</h1>

            <p>Vierailevat käyttäjät:</p>
            <p>
              Käyttäjän IP-osoite sekä käyttämänsä laitteiston metatietoja,
              jolla käyttäjä vierailee palvelussa.
            </p>

            <p>Rekisteröityneet käyttäjät:</p>
            <p>
              Palvelussa käsitellään seuraavia tietoja rekisteröityneistä
              käyttäjistä;
            </p>
            <ul>
              <li>Nimi</li>
              <li>Sähköpostiosoite</li>
              <li>Puhelinnumero</li>
              <li>
                Yritysnimi ja ilmoitetut yritystiedot kuten Y-tunnus sekä
                osoite- ja laskutustiedot
              </li>
              <li>Käyttäjätaso (organisaatio)</li>
              <li>
                Palvelun käyttötiedot [tilaustiedot (tilauksen sisältö,
                ajankohta, hinta, paikkakunta), omien työntekijöiden tarjonta,
                tehdyt sopimukset, hakutoiminnot ja selaushistoria]
              </li>
              <li>
                Palvelussa syötetyt työntekijätiedot [nimi, koulutus,
                työkokemus, työnteko paikkakunta, rintakuva, ammattiosaaminen]
                (Palvelu ei vaadi ilmoitettavien työntekijöiden henkilötunnusta,
                osoitetietoja, puhelinnumeroa tai muita henkilökohtaiseksi
                luettavia erityistietoja.)
              </li>
              <li>Asiakaspalveluyhteydenottoja koskevat tiedot</li>
              <li>Asiakastapahtumia koskevat tiedot</li>
            </ul>

            <h2>Tietojen hyväksytyt alikäsittelijät:</h2>
            <p>Ohjelmisto (ylläpito) – Haltu Oy (2324740-1)</p>
            <p>
              Palvelu (tarjotut työntekijät ja yritystiedot) – Rekisteröityneet
              palvelun muut käyttäjät (Rajoitetuin oikeuksin)
            </p>

            <h2>Käsittelyn oikeusperuste:</h2>

            <p>
              <b>Sopimus:</b>
            </p>
            <p>
              Vaihtopenkki-palvelun toteuttamisen (asiakastilin luominen ja
              hallinnointi ja palvelun tarjoaminen) osalta käsittelyn peruste on
              sopimus Vaihtopenkki-palvelun käytöstä (Vaihtopenkki- palvelun
              käyttöehdot).
            </p>

            <p>
              <b>Tietojenkäsittelijän oikeutettu etu*:</b>
            </p>
            <p>
              Rekisterinpitäjä käsittelee henkilötietoja oikeutetun edun
              perusteella seuraavissa tilanteissa:
            </p>
            <ul>
              <li>
                Asiakastyytyväisyyden mittaaminen asiakaskokemuksen
                kehittämismielessä
              </li>
              <li>
                Asiakaspalautteen käsitteleminen, mm. kysymyksiin vastaaminen,
                ongelmatilanteiden selvittäminen, virheiden korjaaminen,
                häiriötilanteiden ja uhkausten selvittäminen.
              </li>
              <li>
                Verkkopalvelun käytön analysointi ja toiminnan varmistaminen
              </li>
              <li>
                Asiointikieltoon asetetun henkilön ja/tai yrityksen tietojen
                käsittely
              </li>
              <li>Ulosotto tai velanperintätoimenpiteiden käsittely</li>
            </ul>

            <p>
              <i>
                * Oikeutettu etu tarkoittaa rekisterinpitäjän toimintaan
                olennaisesti liittyvää käsittelyä, jonka asiakas voi kohtuudella
                olettaa kuuluvan rekisterinpitäjän toimiin. Rekisterinpitäjän on
                usein käsiteltävä henkilötietoja voidakseen suorittaa
                liiketoimintaan kuuluvia tehtäviä. Henkilötietojen käsittelyä ei
                tässä yhteydessä voida välttämättä perustella lakisääteisellä
                velvoitteella tai sopimuksen perusteella. Henkilötietojen
                käsittely saattaa kuitenkin olla perusteltua ”oikeutetun edun”
                perusteella. Tällöin tietojen käsittelystä oikeutetun edun
                perusteella on aina arvioitava etukäteen, ettei oikeutetun edun
                mukaisesta toiminnasta aiheudu oleellista haittaa
                rekisteröityjen oikeuksille ja vapauksille.
              </i>
            </p>

            <p>
              <b>Lakisääteinen velvoite:</b>
            </p>
            <p>
              Oikeusperuste on lakisääteinen velvoite, kun käsittelemme
              henkilötietoja soveltuvan lainsäädännön velvoitteiden
              noudattamiseksi kuten kirjanpitotarkoituksiin.
            </p>

            <h2>Käyttäjän oikeudet tietosuoja-asioissa</h2>
            <p>
              Käyttäjällä on tietosuoja-asetuksen mukainen oikeus seuraaviin
              toimiin omien tietojensa osalta:
            </p>
            <ul>
              <li>Oikeus saada pääsy tietoihin.</li>
              <li>Oikeus tietojen oikaisemiseen.</li>
              <li>Oikeus käsittelyn rajoittamiseen.</li>
              <li>Vastustamisoikeus.</li>
              <li>
                Oikeus tietojen poistamiseen. Joissain tapauksissa
                henkilötietojen käsittelylle ja säilyttämiselle saattaa
                kuitenkin olla oikeutettu peruste, kuten esimerkiksi
                lakisääteisten velvoitteiden täyttäminen.
              </li>
              <li>Oikeus siirtää tiedot järjestelmästä toiseen.</li>
              <li>Oikeus peruuttaa suostumus.</li>
            </ul>
            <p>
              Yllämainitut käyttäjän oikeuksiin perustuvat pyynnöt pitää
              osoittaa suoraan ilmoitetulle yhteyshenkilölle.
            </p>

            <h2>Evästeiden käyttö</h2>
            <p>
              Eväste (cookie) on dataa (tyypillisesti tekstitiedosto), jonka
              internetselain tallentaa käyttäjän laitteelle.
            </p>

            <h2>Palvelun mahdollistavat ja sitä parantavat evästeet</h2>
            <p>
              Osa evästeistä on välttämättömiä palvelun sujuvalle käytölle.
              Tällaisia ovat esimerkiksi kirjautumisen tai kielivalinnat
              mahdollistavat evästeet. Käytämme evästeitä myös ymmärtääksemme
              paremmin, miten asiakkaat käyttävät sivustojamme. Keräämme
              tilastotietoa kävijämääristä ja asiakkaiden käyttämästä
              sisällöstä, jotta voimme varmistua sivustomme vastaavan käyttäjien
              tarpeita. Seuraamme myös millaisilla laitteilla ja mihinkä
              vuorokauden aikaan käyttö tapahtuu.
            </p>

            <h2>Käyttäjän mahdollisuus estää evästeiden käyttö</h2>
            <p>
              Käyttäjä voi asettaa selaimestaan, mitkä evästeet sallitaan
              palvelussa. Mikäli toiminto kytketään pois, on hyvä ottaa
              huomioon, että evästeet saattavat olla tarpeellisia joidenkin
              palveluiden asianmukaiselle toimimiselle. Käyttäjä voi myös olla
              kokonaan hyväksymättä evästeitä, kun palvelu sitä kysyy
              rekisteröitymisvaiheessa.
            </p>

            <h2>Tietojen suojaus</h2>
            <p>
              Käytämme teknisiä ja organisatorisia tietoturvakeinoja
              henkilötietojen suojaamiseksi oikeudetonta pääsyä, luovuttamista,
              hävittämistä tai muuta oikeudetonta käsittelyä. Haavoittuvuuksia
              seurataan ja toimiin ryhdytään tarpeen mukaan. Mahdollisia
              ongelmia hallitaan, niistä raportoidaan ja niihin reagoidaan
              asianmukaisesti.
            </p>

            <h2>Tekniset menetelmät:</h2>
            <p>
              Tietoturvallisten palvelinratkaisuiden käyttö, palomuurien käyttö,
              verkkoliikenteen salaustekniikoiden käyttö, salausavainten ja
              tietojen kryptaamisen sekä käyttöseurannan valvonta.
            </p>

            <h2>Organisatoriset menetelmät:</h2>
            <p>
              Turvallisten laitteiden käyttö, asianmukainen kulunvalvonta,
              hallittu sekä varmistettu käyttöoikeuksien myöntäminen ja niiden
              käytön valvonta. Henkilötietojen käsittelystä vastaavan
              henkilöstön ohjeistaminen ja sitouttaminen
              salassapitovelvollisuuteen. Mahdolliset alihankkijat valitaan
              huolella ja varmistetaan heidän ymmärrys tietosuoja-asetuksista.
            </p>

            <h2>Tietojen säännönmukainen luovuttaminen</h2>
            <p>
              Tietoja ei luovuteta eteenpäin palvelun ulkopuolelle lukuun
              ottamatta tässä dokumentissa mainittuja kolmansia osapuolia, ellei
              laki sitä erikseen vaadi.
            </p>

            <h2>Tietojen siirtäminen EU:n tai ETA:n ulkopuolelle</h2>
            <p>
              Tietoja ei tarkoituksettomasti siirretä EU:n tai ETA:n
              ulkopuolelle. Sellaisissa tapauksissa, joissa tietoja siirretään,
              huolehditaan samalla, että yhteistyökumppani on Privacy
              Shield-rekisteröity yhtiö tai yhteisö tai että siirto toteutetaan
              muualla lainsäädännön sallimalla tavalla.
            </p>

            <h2>Tietojen säilytys</h2>
            <p>
              Säilytämme käyttäjien tietoja niin kauan kuin on tarpeen
              tarkoitusten toteuttamiseksi kulloinkin voimassa olevan
              lainsäädännön mukaisesti.
            </p>
          </Typography>
        </Grid.Cell>
      </Grid>
    </React.Fragment>
  );
}

import { SearchEmployeesForm } from 'forms/Employee';
import React from 'react';

import { SearchDialog } from './SearchDialog';

export function SearchEmployeesDialog() {
  return (
    <SearchDialog
      formClass={SearchEmployeesForm}
      pathname={'/employees'}
      title={'Hae työntekijöitä'}
    />
  );
}

import { List } from 'lib/rmwc';
import React from 'react';
import { Link } from 'react-router-dom';

import { EmployeeAvatar } from './EmployeeAvatar';

export function EmployeeListItem({ employee, to, location }) {
  return (
    <List.Item
      theme="secondaryBg"
      tag={Link}
      to={{
        pathname: to,
        state: { previous: location },
      }}
    >
      <List.Item.Graphic icon={<EmployeeAvatar employee={employee} />} />
      <List.Item.Text>
        <List.Item.PrimaryText>{employee.name}</List.Item.PrimaryText>
        <List.Item.SecondaryText>
          {employee.profession?.name}
        </List.Item.SecondaryText>
      </List.Item.Text>
    </List.Item>
  );
}

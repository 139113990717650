import { IsAvailableCheckBox } from 'components/forms/fields/Checkbox';
import { ImageField } from 'components/forms/fields/ImageField';
import { YearsOfExperienceSelect } from 'components/forms/fields/Select';
import { LicencesAutocompleteMultiSelect } from 'components/forms/fields/Select';
import { ProvinceAutocompleteSelect } from 'components/forms/fields/Select';
import { CompetenciesAutocompleteMultiSelect } from 'components/forms/fields/Select';
import { ProfessionAutocompleteSelect } from 'components/forms/fields/Select';
import { IndustryAutocompleteSelect } from 'components/forms/fields/Select';
import { TextField } from 'lib/rmwc';
import { Employee } from 'models/Employee';
import { Form } from 'orm';

export class CreateEmployeeForm extends Form {
  static model = Employee;

  static formatData = (inputData) => {
    const data = { ...inputData };
    data.is_available = Boolean(data.is_available);
    if (data.cost_per_hour) {
      data.cost_per_hour = data.cost_per_hour.replace(',', '.');
    }
    if (!data.picture) {
      delete data.picture;
    }
    return data;
  };

  // using FormData is necessary for uploading images
  static constructFormData = (inputData) => {
    const data = CreateEmployeeForm.formatData(inputData);
    const formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
      if (Array.isArray(value)) {
        for (const arrayValue of value) {
          formData.append(key, arrayValue);
        }
      } else {
        formData.append(key, value);
      }
    }
    return formData;
  };

  static onSubmit = ({ data, actions, session }) => {
    return actions.post({
      headers: session.headers,
      data: this.constructFormData(data),
    });
  };

  static fields = {
    id: new this.ID(),
    name: new this.CharField(TextField, {
      maxLength: 10,
      extra: {
        span: 6,
        label: 'Nimi *',
        type: 'text',
        outlined: true,
        fullwidth: true,
      },
    }),
    picture: new this.CharField(ImageField, {
      extra: {
        id: 'files',
        span: 6,
        label: 'Kuva',
        type: 'file',
        outlined: true,
        fullwidth: true,
      },
    }),
    industry: new this.CharField(IndustryAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Toimiala',
        outlined: true,
        fullwidth: true,
        // disabled: true,
      },
    }),
    profession: new this.CharField(ProfessionAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Ammatti *',
        outlined: true,
        fullwidth: true,
      },
    }),
    competencies: new this.ArrayField(CompetenciesAutocompleteMultiSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Työtehtävät ja vastuualueet',
        outlined: true,
        fullwidth: true,
      },
    }),
    experience_years: new this.CharField(YearsOfExperienceSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Työkokemus (vuotta) *',
        outlined: true,
        fullwidth: true,
        enhanced: true,
      },
    }),
    licences: new this.ArrayField(LicencesAutocompleteMultiSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Kortit',
        outlined: true,
        fullwidth: true,
      },
    }),
    province: new this.CharField(ProvinceAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Työskentelykunta *',
        textarea: false,
        outlined: true,
        fullwidth: true,
      },
    }),
    cost_per_hour: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Tuntihinta (ALV 0%) *',
        type: 'text',
        pattern: '^\\d+([,\\.]\\d\\d?)?$',
        min: 1,
        max: 1000,
        outlined: true,
        fullwidth: true,
      },
    }),
    description: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Kuvaus',
        type: 'text',
        textarea: true,
        outlined: true,
        fullwidth: true,
      },
    }),
    available_from: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Käytettävissä lähtien *',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
    available_to: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Käytettävissä saakka *',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
    is_available: new this.BooleanField(IsAvailableCheckBox, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'On saatavilla',
      },
    }),
  };
}

export class EditEmployeeForm extends CreateEmployeeForm {
  static onSubmit = ({ data, actions, session, model }) => {
    return actions.put({
      headers: session.headers,
      id: data.id,
      data: this.constructFormData(data),
    });
  };
}

export class LeaseEmployeeForm extends EditEmployeeForm {
  static onSubmit = this.putSubmit;
}

export class SearchEmployeesForm extends Form {
  static model = Employee;
  static fields = {
    company__industry: new this.NumberField(IndustryAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Toimiala',
        outlined: true,
        fullwidth: true,
        clearable: true,
      },
    }),
    profession: new this.NumberField(ProfessionAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 6,
        label: 'Ammatti',
        outlined: true,
        fullwidth: true,
        clearable: true,
      },
    }),
    competencies: new this.NumberArrayField(
      CompetenciesAutocompleteMultiSelect,
      {
        maxLength: 500,
        extra: {
          span: 6,
          label: 'Työtehtävät ja vastuualueet',
          outlined: true,
          fullwidth: true,
          clearable: true,
        },
      },
    ),
    experience_years__gte: new this.CharField(YearsOfExperienceSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Työkokemus (vuotta)',
        outlined: true,
        fullwidth: true,
        enhanced: true,
      },
    }),
    licences: new this.ArrayField(LicencesAutocompleteMultiSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Kortit',
        outlined: true,
        fullwidth: true,
        clearable: true,
      },
    }),
    province: new this.CharField(ProvinceAutocompleteSelect, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Työskentelykunta',
        outlined: true,
        fullwidth: true,
        clearable: true,
      },
    }),
    available_from__lte: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Tarvitaan lähtien',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
    available_to__gte: new this.CharField(TextField, {
      maxLength: 500,
      extra: {
        span: 12,
        label: 'Tarvitaan saakka',
        type: 'date',
        outlined: true,
        fullwidth: true,
        floatLabel: true,
      },
    }),
  };
}

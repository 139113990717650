import { SessionContext } from 'App';
import BasicForm from 'components/forms/BasicForm';
import { CreateEmployeeForm, EditEmployeeForm } from 'forms/Employee';
import { Dialog } from 'lib/rmwc';
import { useForm } from 'orm';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export function CreateEmployeeDialog() {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const currentUser = session.User.first();
  const industry = currentUser?.profile?.company?.ref?.industry;

  const form = useForm(
    session,
    CreateEmployeeForm,
    {
      defaultValues: {
        competencies: [],
        licences: [],
        industry: industry?.id ?? industry,
        is_available: true,
      },
    },
    [industry],
  );

  const [open, setOpen] = useState(true);
  const previous = location?.state?.previous || '/home';

  const dialogActions = {
    close: () => {
      return previous;
    },
    cancel: () => {
      if (form?.reset) {
        form.reset();
      }
      return previous;
    },
    submit: async (e) => {
      if (form?.onSubmit) {
        const response = await form.onSubmit(e);
        if (response && !response.errors) {
          form.reset();
        } else {
          setOpen(true);
        }
      }
      return previous;
    },
  };

  async function onClosed(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClosed}>
      <Dialog.Title>Lisää työntekijä</Dialog.Title>
      <Dialog.Content>
        <p>
          Huom! Jotta voit lisätä työntekijän, yritystietosi tulee olla
          kirjattuna ja toimiala valittuna. Jos haluat lisätä eri toimialojen
          työntekijöitä, vaihda toimialaa yritystiedoissa.
        </p>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="cancel">
          Peruuta
        </Dialog.Button>
        <Dialog.Button
          theme="textPrimaryOnLight"
          disabled={form.pristine || form.submitting || !form.onSubmit}
          action="submit"
          isDefaultAction
        >
          Tallenna
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function EditEmployeeDialog({ employee = {} }) {
  const session = useContext(SessionContext);
  const history = useHistory();
  const location = useLocation();
  const currentUser = session.User.first();
  const industry = currentUser?.profile?.company?.ref?.industry;

  const form = useForm(
    session,
    EditEmployeeForm,
    {
      defaultValues: {
        ...employee?.ref,
        picture: null,
        industry: industry?.id ?? industry,
      },
    },
    [employee?.ref, industry],
  );

  const [open, setOpen] = useState(true);
  const previous = location?.state?.previous || `/employee/${employee?.id}`;

  const dialogActions = {
    close: () => {
      return previous;
    },
    cancel: () => {
      form.reset();
      return previous;
    },
    submit: async (e) => {
      const response = await form.onSubmit(e);
      if (!response?.errors) {
        form.reset();
        return previous;
      }
      setOpen(true);
    },
  };

  async function onClosed(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <Dialog open={open} onClose={() => setOpen(false)} onClosed={onClosed}>
      <Dialog.Title>Muokkaa työntekijää</Dialog.Title>
      <Dialog.Content>
        <BasicForm form={form} />
      </Dialog.Content>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="cancel">
          Peruuta
        </Dialog.Button>
        <Dialog.Button
          theme="textPrimaryOnLight"
          disabled={form.pristine || form.submitting}
          action="submit"
          isDefaultAction
        >
          Tallenna
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

export function DeleteEmployeeDialog({ employee = {} }) {
  const location = useLocation();
  const session = useContext(SessionContext);
  const history = useHistory();
  const [open, setOpen] = useState(true);
  const { Employee } = useContext(SessionContext);

  const previous = location?.state?.previous || `/employee/${employee?.id}`;

  const dialogActions = {
    close: () => {
      return previous;
    },
    cancel: () => {
      return previous;
    },
    delete: async (e) => {
      const response = await Employee.actions.delete({
        id: employee.id,
        headers: session.headers,
      });
      if (!response?.errors) {
        return '/home';
      }
      setOpen(true);
    },
  };

  async function onClosed(e) {
    const next = await dialogActions[e.detail.action]();
    if (next) {
      history.push(next);
      // history.go(0);
    }
  }

  return (
    <Dialog open={open} onClosed={onClosed}>
      <Dialog.Title>Poistetaanko työntekijä?</Dialog.Title>
      <Dialog.Actions>
        <Dialog.Button theme="textPrimaryOnLight" action="cancel">
          Peruuta
        </Dialog.Button>
        <Dialog.Button
          theme="textPrimaryOnLight"
          action="delete"
          isDefaultAction
        >
          Poista
        </Dialog.Button>
      </Dialog.Actions>
    </Dialog>
  );
}

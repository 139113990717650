import { SessionContext } from 'App';
import {
  LoginDialog,
  PasswordResetDialog,
  RegisterDialog,
} from 'components/dialogs/Auth';
import TopAppBar from 'components/snippets/TopAppBar';
import ServiceDescription from 'components/views/ServiceDescription';
import { Button, Grid, Typography } from 'lib/rmwc';
import React, { useContext } from 'react';
import { Link, Redirect, Route } from 'react-router-dom';

export default function LandingView() {
  const { CurrentUser } = useContext(SessionContext);

  return (
    <React.Fragment>
      {CurrentUser.isLoggedIn && <Redirect to="/home" />}

      <Route path="/login" component={LoginDialog} />
      <Route path="/register" component={RegisterDialog} />
      <Route path="/password/reset" component={PasswordResetDialog} />

      <TopAppBar progress={false}>
        <TopAppBar.Section alignEnd>
          <Button theme={['onPrimary']} tag={Link} to="/login">
            Kirjaudu sisään
          </Button>
          <Button theme={['onPrimary']} tag={Link} to="/register">
            Rekisteröidy
          </Button>
        </TopAppBar.Section>
      </TopAppBar>

      <Grid style={{ maxWidth: 1200 }}>
        <Grid.Row style={{ marginBottom: 30 }}>
          <Grid.Cell span={12} style={{ justifySelf: 'center' }}>
            <Typography className="mdc-theme--on-primary" use="headline4">
              Tervetuloa Vaihtopenkki-sovellukseen
            </Typography>
          </Grid.Cell>
        </Grid.Row>
        <Grid.Row style={{ marginBottom: 30 }}>
          <Grid.Cell span={12} style={{ justifySelf: 'center' }}>
            <ServiceDescription />
          </Grid.Cell>
        </Grid.Row>
      </Grid>
    </React.Fragment>
  );
}

import { Card, Typography } from 'lib/rmwc';
import React from 'react';

export function TermsOfServiceCard({
  theme = '',
  themeButton = '',
  outlined = false,
}) {
  return (
    <Card outlined={outlined} theme={theme}>
      <div style={{ padding: '1rem' }}>
        <Typography
          theme={themeButton}
          tag="a"
          href="https://www.vaihtopenkki.net/kayttoehdot-ja-tietosuoja"
          target="_blank"
          rel="noopener noreferrer"
        >
          Yleiset sopimusehdot
        </Typography>
      </div>
    </Card>
  );
}

import { SessionContext } from 'App';
import { IconButton, LinearProgress, List, Menu, TopAppBar } from 'lib/rmwc';
import React, { useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';

export default function TopAppBarCustom({
  children,
  progress = true,
  navigationMenu = undefined,
  navigationMenuTo = '',
}) {
  const [open, setOpen] = React.useState(false);
  const history = useHistory();

  const { User, CurrentUser } = useContext(SessionContext);

  async function logout() {
    await User.actions.post({
      url: `${process.env.REACT_APP_AUTH_URL}/logout/`,
    });
    User.clear();
    history.go(0);
  }

  return (
    <React.Fragment>
      <TopAppBar className="mdc-top-app-bar--custom" theme="secondaryBg" short>
        <LinearProgress
          className="mdc-linear-progress--custom"
          progress={progress ? undefined : 0}
          buffer={1}
        />
        <TopAppBar.Row>
          <TopAppBar.Section>
            {navigationMenu === 'menu' && (
              <Menu.SurfaceAnchor>
                <Menu open={open} onClose={(e) => setOpen(false)}>
                  {CurrentUser.hasCreatedCompany && (
                    <>
                      <Menu.Item tag={Link} to="/home">
                        Omat työntekijät ja -tehtävät
                      </Menu.Item>
                      <Menu.Item tag={Link} to="/contracts">
                        Sopimukset
                      </Menu.Item>
                      <Menu.Item tag={Link} to="/profile">
                        Profiili
                      </Menu.Item>
                      <List.Divider />
                      <Menu.Item tag={Link} to="/employees">
                        Hae työntekijöitä
                      </Menu.Item>
                      <Menu.Item tag={Link} to="/jobnotices">
                        Hae työtehtäviä
                      </Menu.Item>
                      <List.Divider />
                    </>
                  )}
                  <Menu.Item tag={Link} to="/" onClick={logout}>
                    Kirjaudu ulos
                  </Menu.Item>
                </Menu>
                <IconButton icon="menu" onClick={(e) => setOpen(!open)} />
              </Menu.SurfaceAnchor>
            )}
            {navigationMenu === 'arrow' && (
              <TopAppBar.NavigationIcon
                icon="arrow_back"
                tag={Link}
                to={navigationMenuTo}
              />
            )}
            <TopAppBar.Title>
              <Link to="/">
                <img alt="Logo" src="/img/logo.svg" />
              </Link>
            </TopAppBar.Title>
          </TopAppBar.Section>
          {children}
        </TopAppBar.Row>
      </TopAppBar>
      <TopAppBar.FixedAdjust />
    </React.Fragment>
  );
}

TopAppBarCustom.Row = TopAppBar.Row;
TopAppBarCustom.Section = TopAppBar.Section;
TopAppBarCustom.NavigationIcon = TopAppBar.NavigationIcon;
TopAppBarCustom.Title = TopAppBar.Title;
TopAppBarCustom.ActionItem = TopAppBar.ActionItem;
TopAppBarCustom.FixedAdjust = TopAppBar.FixedAdjust;

import React from 'react';
import { Typography } from 'lib/rmwc';

export default function ServiceDescription() {
  return (
    <Typography className="mdc-theme--on-primary">
      <p>
        Vaihtopenkki on kotimainen henkilöstön välittämiseen kehitetty palvelu,
        joka tarjoaa yrittäjille perinteisestä poikkeavan kokemuksen.
      </p>
      <p>
        Näemme, että nykyinen markkinatilanne vaatii useilla aloilla uudistuksia
        ammattimaisen työvoiman saamiseksi ja riittävyyden turvaamiseksi.
      </p>
      <p>
        Olemme ratkaisseet ongelman uudella henkilöstön vaihtopalvelulla, joka
        tarjoaa yrittäjälle helpon ja luotettavan palvelukokonaisuuden.
      </p>
      <p>
        Järjestelmämme avulla jokainen voi resursoida yrityksensä henkilöstön
        vallitsevan tilanteen sekä tarpeen mukaan.
      </p>
    </Typography>
  );
}

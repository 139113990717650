import { Model } from 'orm';

export class AccountDelete extends Model {
  static modelName = 'AccountDelete';

  static fields = {
    email: new this.CharField(),
    company: new this.CharField(),
    info: new this.CharField(),
  };

  static options = {
    ...Model.options,
    apiEndpoint: `${process.env.REACT_APP_API_URL}/delete-account-request`,
  };
}

import { Button, Card, List, Typography } from 'lib/rmwc';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';

import { SimpleTable } from '../SimpleTable';

export function ContractDetailsCard({
  contract,
  theme = '',
  themeText = '',
  themeButton = '',
  outlined = false,
  editable = false,
}) {
  const location = useLocation();

  const tableItems = [
    [
      'Sopimuksen ajankohta:',
      contract?.start_time && contract?.end_time
        ? contract?.start_time + ' — ' + contract?.end_time
        : undefined,
    ],
    ['Työkohteen osoite, kellonaika ja lisätiedot:', contract?.location],
    [
      'Työtehtävää kuvaava ammattinimike:',
      contract?.profession?.name || contract?.employee?.profession?.name,
    ],
    ['Työntekijän nimi:', contract?.employee?.name],
  ];

  if (
    contract?.profession?.name &&
    contract?.employee?.profession?.name &&
    contract.profession.name !== contract.employee.profession.name
  ) {
    tableItems.push([
      'Työntekijän ammattinimike:',
      contract?.employee?.profession?.name,
    ]);
  }

  return (
    <Card outlined={outlined} theme={theme}>
      <Typography
        use="subtitle1"
        tag="div"
        style={{ padding: '0.5rem 1rem' }}
        theme={themeText}
      >
        Sopimustiedot
        {editable && (
          <Button
            tag={Link}
            to={{
              pathname: location.pathname + '/fill',
              state: { previous: location },
            }}
            theme={themeButton}
            style={{ float: 'right', height: 28 }}
          >
            Täytä tietoja
          </Button>
        )}
      </Typography>
      <List.Divider />
      <SimpleTable theme={theme} themeText={themeText} items={tableItems} />
    </Card>
  );
}

import { TextField } from 'lib/rmwc';
import { User } from 'models/User';
import { Form } from 'orm';

export default class PasswordResetForm extends Form {
  static model = User;

  static onSubmit = ({ data, actions }) => {
    return actions.post({
      url: `${process.env.REACT_APP_AUTH_URL}/password/reset/`,
      data,
    });
  };

  static fields = {
    email: new this.CharField(TextField, {
      extra: {
        span: 12,
        label: 'Sähköpostiosoite *',
        outlined: true,
        fullwidth: true,
      },
    }),
  };
}

import { Avatar } from '@rmwc/avatar';
import { Badge } from '@rmwc/badge';
import { Portal } from '@rmwc/base';
import { Button } from '@rmwc/button';
import {
  Card,
  CardActionButton,
  CardActionButtons,
  CardActionIcon,
  CardActionIcons,
  CardActions,
  CardMedia,
  CardMediaContent,
  CardPrimaryAction,
} from '@rmwc/card';
import { Checkbox } from '@rmwc/checkbox';
import { CircularProgress } from '@rmwc/circular-progress';
import {
  DataTable,
  DataTableBody,
  DataTableCell,
  DataTableContent,
  DataTableHead,
  DataTableHeadCell,
  DataTableRow,
  SimpleDataTable,
} from '@rmwc/data-table';
import {
  Dialog,
  DialogActions,
  DialogButton,
  DialogContent,
  DialogTitle,
} from '@rmwc/dialog';
import {
  Drawer,
  DrawerAppContent,
  DrawerContent,
  DrawerHeader,
  DrawerSubtitle,
  DrawerTitle,
} from '@rmwc/drawer';
import { Fab } from '@rmwc/fab';
import { Grid, GridCell, GridRow } from '@rmwc/grid';
import { Icon } from '@rmwc/icon';
import { IconButton } from '@rmwc/icon-button';
import { LinearProgress } from '@rmwc/linear-progress';
import {
  List,
  ListDivider,
  ListGroup,
  ListItem,
  ListItemGraphic,
  ListItemMeta,
  ListItemPrimaryText,
  ListItemSecondaryText,
  ListItemText,
} from '@rmwc/list';
import { Menu, MenuItem, MenuSurface, MenuSurfaceAnchor } from '@rmwc/menu';
import { Ripple } from '@rmwc/ripple';
import { Select } from '@rmwc/select';
import { Snackbar, SnackbarAction } from '@rmwc/snackbar';
import { Switch } from '@rmwc/switch';
import { Tab, TabBar } from '@rmwc/tabs';
import { TextField } from '@rmwc/textfield';
import { Theme } from '@rmwc/theme';
import { Tooltip } from '@rmwc/tooltip';
import {
  TopAppBar,
  TopAppBarActionItem,
  TopAppBarFixedAdjust,
  TopAppBarNavigationIcon,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarTitle,
} from '@rmwc/top-app-bar';
import { Typography } from '@rmwc/typography';

Card.PrimaryAction = CardPrimaryAction;
Card.Media = CardMedia;
Card.MediaContent = CardMediaContent;
Card.Actions = CardActions;
Card.ActionIcons = CardActionIcons;
Card.ActionIcon = CardActionIcon;
Card.ActionButtons = CardActionButtons;
Card.ActionButton = CardActionButton;

DataTable.Content = DataTableContent;
DataTable.Head = DataTableHead;
DataTable.Row = DataTableRow;
DataTable.HeadCell = DataTableHeadCell;
DataTable.HeadCell = DataTableHeadCell;
DataTable.HeadCell = DataTableHeadCell;
DataTable.Body = DataTableBody;
DataTable.Row = DataTableRow;
DataTable.Cell = DataTableCell;
DataTable.Simple = SimpleDataTable;

Dialog.Title = DialogTitle;
Dialog.Content = DialogContent;
Dialog.Actions = DialogActions;
Dialog.Button = DialogButton;

Drawer.Header = DrawerHeader;
Drawer.Title = DrawerTitle;
Drawer.Subtitle = DrawerSubtitle;
Drawer.Content = DrawerContent;
Drawer.AppContent = DrawerAppContent;

Grid.Cell = GridCell;
Grid.Row = GridRow;

List.Divider = ListDivider;
List.Group = ListGroup;
List.Item = ListItem;
List.Item.Graphic = ListItemGraphic;
List.Item.Text = ListItemText;
List.Item.PrimaryText = ListItemPrimaryText;
List.Item.SecondaryText = ListItemSecondaryText;
List.Item.Meta = ListItemMeta;

Menu.Item = MenuItem;
Menu.Surface = MenuSurface;
Menu.SurfaceAnchor = MenuSurfaceAnchor;

Snackbar.Action = SnackbarAction;

TabBar.Tab = Tab;

TopAppBar.Row = TopAppBarRow;
TopAppBar.Section = TopAppBarSection;
TopAppBar.NavigationIcon = TopAppBarNavigationIcon;
TopAppBar.Title = TopAppBarTitle;
TopAppBar.ActionItem = TopAppBarActionItem;
TopAppBar.FixedAdjust = TopAppBarFixedAdjust;

export {
  Avatar,
  Badge,
  Button,
  Card,
  Checkbox,
  CircularProgress,
  DataTable,
  Dialog,
  Drawer,
  Fab,
  Grid,
  Icon,
  IconButton,
  LinearProgress,
  List,
  Menu,
  Portal,
  Ripple,
  Select,
  Snackbar,
  Switch,
  TabBar,
  TextField,
  Theme,
  Tooltip,
  TopAppBar,
  Typography,
};
